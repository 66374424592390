.countryList-item[data-v-56e353e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.countrySelectorPopup[data-v-56e353e8] {
  background-color: #f6f8f9;
}
.countrySelectorPopup > h3[data-v-56e353e8] {
  font-size: 15px;
  color: #060606;
  font-weight: bold;
  text-align: center;
  padding: 1.2em 0;
}
.countrySelectorPopup .searchCountryInput[data-v-56e353e8] {
  width: 92%;
  margin: 0 auto;
  border: 0.5px solid #666666;
  padding: 8px 12px;
  margin-bottom: 8px;
  background-color: #f6f8f9;
}
.searchCountryEmpty[data-v-56e353e8] {
  height: 60vh;
}
.countryList[data-v-56e353e8] {
  text-align: center;
  color: #808080;
  font-size: 14px;
  padding: 0 4%;
  height: 60vh;
  overflow-y: scroll;
}
.countryList-item[data-v-56e353e8] {
  padding: 6px 0;
  /*padding-left: 24%;*/
  /*padding-left: 20%;*/
  border-bottom: 0.5px solid #f2f2f3;
  height: 5vh;
  line-height: 5vh;
  font-size: 16px;
  /*margin-top: 10px;*/
}
.countryList-item-img[data-v-56e353e8] {
  width: 30px;
}
.countryList-item.active[data-v-56e353e8] {
  background-color: #ebf7ff;
  color: #108ee8;
}
.countryList-item svg[data-v-56e353e8] {
  font-size: 30px;
}
.countryList-item em[data-v-56e353e8] {
  /*width: 3.6em;*/
  /*margin-left: 2.4em;*/
  /*margin-left: 1em;*/
  /*text-align: left;*/
}