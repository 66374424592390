
.container[data-v-2486b72f]{
  text-align: center;
  font-size: 24px;
  height: 100%;
  width: 100%;
  padding-top: 20%;
}
.imgContainer[data-v-2486b72f]{
  width: 100%;
  text-align: center;
}
.img[data-v-2486b72f]{
}
.paySuccess[data-v-2486b72f]{
  color: green;
  margin-top: 20px;
}
.tagContainer[data-v-2486b72f]{
  text-align: left;
  margin-left: 15%;
  margin-top: 10%;
}
.tagFont[data-v-2486b72f]{
  font-size: 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.tagP[data-v-2486b72f]{
  text-indent: 2em;
}
.btnContainer[data-v-2486b72f]{
  text-align: center;
  width: 100%;
  height: 40px;
  position:relative;
  margin-top: 40%;
}
.btn[data-v-2486b72f]{
  width: 180px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 0.5em;
  border: 1px solid #fcf5f5;
  color: green;
  font-size: 13px;
  line-height: 30px;
  position: absolute;
  margin: auto;
  font-family: fantasy;
  top: 0; left: 0; bottom: 0; right: 0;
  /*bottom: 10%;*/
}
