.detail-box[data-v-b262a966] {
  padding: 0 15px;
  /* padding-bottom: 78px; */
}
.order-detail[data-v-b262a966] {
  padding: 20px;
  text-align: center;
}
.order-detail .logoImg[data-v-b262a966] {
  width: 55px;
  height: 55px;
}
.order-detail > .title[data-v-b262a966] {
  font-size: 14px;
  margin-top: 15px;
}
.order-detail > .price[data-v-b262a966] {
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.order-info[data-v-b262a966] {
  border-top: 1px solid #ddd;
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
}
.order-table[data-v-b262a966] {
  /* border: 1px solid #eae9ed; */
}
.order-table > div[data-v-b262a966] {
  /* display: flex; */
  /* flex-wrap:wrap; */
  /* border-bottom: 1px solid #eae9ed; */
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.order-table > div .left[data-v-b262a966] {
  /* border-right: 1px solid #eae9ed; */
  width: 100px;
  padding: 10px 0;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  color: #939393;
}
.order-table > div .right[data-v-b262a966] {
  /* flex: 1; */
  padding: 10px 0;
  padding-left: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  word-wrap: break-word;
  word-break: normal;
  float: left;
  /* border-left: 1px solid #eae9ed; */
  width: calc(100% - 100px);
  /* font-weight: 600; */
}
.order-table > div[data-v-b262a966]:nth-child(2n+1) {
  /* background-color: #f2f2f2; */
}
.serve[data-v-b262a966] {
  width: 90%;
  margin: 0 auto;
}
.serve .title[data-v-b262a966] {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #ddd;
}
.serve .serve-type[data-v-b262a966] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.serve .serve-type .serve-item[data-v-b262a966] {
  /* flex: 1; */
  width: 50%;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  color: #2440b3;
}
.pinCode[data-v-b262a966] {
  word-break: break-all;
  overflow-wrap: break-word;
  font-size: 14px;
  background-color: whitesmoke;
  border-radius: 0.4em;
  text-align: center;
}
.copyButton[data-v-b262a966] {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background-color: #ff7772;
  color: #fff;
  border: none;
  border-radius: 0.4em;
}
.rechargeMethod[data-v-b262a966] {
  color: rgba(109, 109, 18, 0.85);
  font-size: 14px;
  word-break: break-all;
  word-wrap: break-word;
  background-color: whitesmoke;
  border-radius: 0.4em;
  padding: 2px 3px;
}
em[data-v-b262a966] {
  padding: 15px 0.6em;
  font-size: 18px;
  display: inline-block;
  border-radius: 4px;
  margin: 10px auto;
  display: block;
  text-align: center;
  width: 250px;
}
em.pay[data-v-b262a966] {
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 225, 78)), to(rgb(255, 196, 78)));
  background-image: linear-gradient(to right, rgb(255, 225, 78), rgb(255, 196, 78));
}
em.close[data-v-b262a966] {
  background-image: -webkit-gradient(linear, left top, right top, from(#f95668), to(#ee0a24));
  background-image: linear-gradient(to right, #f95668, #ee0a24);
  margin-left: 4px;
}
.gongZhongHao[data-v-b262a966] {
  border: 1px solid #e5e5e5;
  padding: 5px 10px 10px 5px;
  color: #b2b2b2;
  /* margin-top: 15px; */
  margin-bottom: 15px;
}
.gongZhongHao .title-desc[data-v-b262a966] {
  font-size: 11px;
}
.gongZhongHao .content[data-v-b262a966] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.gongZhongHao .content .logo[data-v-b262a966] {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}
.gongZhongHao .content .logo img[data-v-b262a966] {
  width: 50px;
  height: 50px;
}
.gongZhongHao .content .title[data-v-b262a966] {
  min-width: 60%;
  margin-left: 8px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.gongZhongHao .content .title .name[data-v-b262a966] {
  color: #000;
  margin-bottom: 5px;
  font-size: 16px;
}
.gongZhongHao .content .title .desc[data-v-b262a966] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-top: 7px;
}
.gongZhongHao .content .guanzhu[data-v-b262a966] {
  padding: 0 15px;
  color: #1aad19;
  border: 1px solid #1aad19;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 5px;
}
.gzhImgStyle[data-v-b262a966] {
  padding: 0 !important;
}
.couponeUser .box[data-v-b262a966] {
  width: 34px;
  height: 34px;
  border: 1px solid #fff;
  border-radius: 17px;
  position: relative;
  margin: 15px auto 0;
}
.couponeUser .box .coupone-chahao[data-v-b262a966] {
  color: #fff !important;
  font-size: 24px !important;
  position: absolute;
  top: 2.5px;
  left: 3.5px;
}
.gzImg[data-v-b262a966] {
  /* position: fixed;
    bottom: 0;
    width: calc(100% - 30px); */
  width: 100%;
  margin-top: 10px;
}
.box-content[data-v-b262a966] {
  font-size: 12px;
  color: #6d6d6d;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-direction: column; */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 8px 16px;
  padding: 8px 0px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.box[data-v-b262a966] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #257ae7;
  color: #fff;
}
.item-card2[data-v-b262a966] {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0 8px 10px;
  /* display: flex;
    justify-content: space-between; */
}
.item-card2 .header[data-v-b262a966] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}
.item-card2 .content[data-v-b262a966] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
}
.item-card2 .content .content-item[data-v-b262a966] {
  width: 172px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 10px;
}
.item-card2 .content .content-item .img[data-v-b262a966] {
  height: 220px;
}
.item-card2 .content .content-item .img img[data-v-b262a966] {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.item-card2 .content .content-item .title[data-v-b262a966] {
  line-height: 28px;
  min-height: 56px;
  margin-top: 6px;
  font-size: 14px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PinButton[data-v-b262a966] {
  /* margin-top: 6px; */
  font-size: 14px;
  border-top: 1px solid rgb(240, 237, 241);
  color: white;
  font-weight: bold;
  padding: 0.3em 0.3em;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, right top, from(#fe6947), to(#fabf2b));
  background-image: linear-gradient(to right, #fe6947, #fabf2b);
  text-align: center;
  display: inline-block;
  margin-left: 4px;
}
.container[data-v-b262a966] {
  width: 360px;
  height: 550px;
  /* letter-spacing: 4px; */
  /* background: #009de0; */
  /* background-image: url("https://oss.globalcharge.cn/prod/wechat/src/assets/userCenter/orderBg.png"); */
  /* background-size: 320px 500px; */
  padding: 20px;
}
.order-title[data-v-b262a966] {
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.order-title img[data-v-b262a966] {
  vertical-align: middle;
  margin-right: 10px;
}
.order-title h2[data-v-b262a966] {
  font-size: 20px;
  /* font-weight: 600; */
}
.divider[data-v-b262a966] {
  height: 0;
  border-bottom: 2px dashed #eee;
  width: 296px;
  margin: 17px auto;
}
.save[data-v-b262a966] {
  line-height: 40px;
  font-size: 16px;
  color: #ed403a;
  text-align: center;
  border-top: 1px solid #eee;
}
.item-card[data-v-b262a966] {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0px 8px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item-card .left[data-v-b262a966] {
  /* flex: 1; */
  width: calc(100% - 110px);
  max-width: 500px;
}
.item-card .left .title[data-v-b262a966] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}
.item-card .left .desc[data-v-b262a966] {
  line-height: 22px;
  font-size: 14px;
  color: #6d6d6d;
  margin-top: 4px;
  word-wrap: break-word;
  word-break: normal;
  min-height: 44px;
}
.item-card .right[data-v-b262a966] {
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}
.box-content[data-v-b262a966] {
  font-size: 12px;
  color: #6d6d6d;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.skipBtnDetail[data-v-b262a966] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin: 8px 16px;
  padding: 8px 0px;
  /* width: 100%; */
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #6d6d6d;
}
.skipBtnDetail .skipBtnDetailIcon[data-v-b262a966] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}