.waitOrderPopup[data-v-f40cbd26] {
  border-radius: 5px;
  font-size: 12px;
  width: 80%;
  color: #414141;
  line-height: 1.5;
}
.waitOrderPopup-title[data-v-f40cbd26] {
  background-color: #108ee8;
  font-size: 16px;
  color: #fff;
  padding: 18px 0;
  text-align: center;
}
.waitOrderPopup-content[data-v-f40cbd26] {
  padding: 23px 36px;
}
.waitOrderPopup-item[data-v-f40cbd26] {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.waitOrderPopup-item-discount[data-v-f40cbd26] {
  color: #ff9a22;
}
.waitOrderPopup-item-payPrice[data-v-f40cbd26] {
  color: #ff3c3c;
}
.waitOrderPopup-item label[data-v-f40cbd26] {
  color: #adadad;
  font-size: 13px;
  width: 8em;
}
.waitOrderPopup-item[data-v-f40cbd26]:last-child {
  margin-bottom: 0;
}
.waitOrderPopup-button[data-v-f40cbd26] {
  color: #108ee8;
  font-size: 15px;
  border-top: 1px solid #d9d9d9;
}
.waitOrderPopup-button > button[data-v-f40cbd26] {
  width: 50%;
  text-align: center;
  background-color: #fff;
  outline: none;
  border: none;
  padding: 13px 0px;
}
.waitOrderPopup-button > button[data-v-f40cbd26]:last-child {
  border-left: 1px solid #d9d9d9;
}