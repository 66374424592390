.bottomButtonBar[data-v-4adf8cd9], .prizeRecord-list-item[data-v-4adf8cd9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.prizeRecord[data-v-4adf8cd9] {
  min-height: 100vh;
  background-color: rgb(243, 243, 243);
  padding: 3%;
}
.prizeRecord-list[data-v-4adf8cd9] {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px 20px 20px 30px;
}
.prizeRecord-list-item[data-v-4adf8cd9] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 15px;
  margin-bottom: 2em;
  position: relative;
}
.prizeRecord-list-item[data-v-4adf8cd9]::before, .prizeRecord-list-item[data-v-4adf8cd9]::after {
  content: "";
  position: absolute;
}
.prizeRecord-list-item[data-v-4adf8cd9]::before {
  background-color: #ddd;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: -1em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.prizeRecord-list-item[data-v-4adf8cd9]::after {
  width: 1em;
  height: 3em;
  background-color: transparent;
  border-color: #E4E4E4;
  border-width: 0 1px 0 0;
  border-style: dotted;
  left: -1.75em;
  top: 50%;
}
.prizeRecord-list-item[data-v-4adf8cd9]:last-child {
  margin-bottom: 0;
}
.prizeRecord-list-item[data-v-4adf8cd9]:last-child::after {
  display: none;
}
.prizeRecord-list-item em[data-v-4adf8cd9] {
  color: #333333;
  font-weight: bold;
}
.prizeRecord-list-item p[data-v-4adf8cd9] {
  color: #bbb;
  font-size: 12px;
}
.bottomButtonBar[data-v-4adf8cd9] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  left: 0;
  width: 100%;
  font-size: 18px;
  color: #fff;
  bottom: 0;
  padding: 0 3%;
  padding-bottom: 150px;
}
.bottomButtonBar a[data-v-4adf8cd9] {
  width: 45%;
  padding: 0.8em 0;
  text-align: center;
  border-radius: 5px;
  background-color: #FC6969;
}