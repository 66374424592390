.couponWrapper .couponList-item[data-v-50005ba8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.couponWrapper[data-v-50005ba8] {
  background-color: #F6F8F9;
  min-height: 100vh;
  padding-bottom: 50px;
}
.couponWrapper .emptyTips[data-v-50005ba8] {
  color: #aaa;
  font-size: 15px;
  text-align: center;
  padding-top: 4vh;
}
.couponWrapper > h6[data-v-50005ba8] {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: #060606;
  padding: 18px 0;
  border-bottom: 1px solid #F2F2F3;
}
.couponWrapper .couponList[data-v-50005ba8] {
  padding: 8.5px;
}
.couponWrapper .couponList-item[data-v-50005ba8] {
  background-color: #fff;
  padding: 6.5px 8px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.couponWrapper .notUseCoupon[data-v-50005ba8] {
  position: fixed;
  width: 100%;
  text-align: center;
  padding: 1em 0;
  font-size: 0.4rem;
  left: 0;
  bottom: 0;
  border-top: 1px solid #f2f2f2;
  background-color: #F6F8F9;
}