#order-complaint[data-v-304c6f0e] {
  position: relative;
}
#order-complaint .header[data-v-304c6f0e] {
  background-color: #3bb270;
  color: #fff;
  font-size: 18px;
  height: 120px;
  line-height: 80px;
  padding-left: 30px;
}
.complaint-box[data-v-304c6f0e] {
  position: absolute;
  top: 75px;
  background-color: #fff;
  /* padding: 0 20px; */
  width: 92%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 10px;
}
.card[data-v-304c6f0e] {
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
}
.content[data-v-304c6f0e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}
.content .logo[data-v-304c6f0e] {
  width: 45px;
  height: 45px;
  margin-top: 5px;
}
.content .logo img[data-v-304c6f0e] {
  width: 45px;
  height: 45px;
}
.content .title[data-v-304c6f0e] {
  min-width: 60%;
  margin-left: 14px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.content .title .name[data-v-304c6f0e] {
  color: #000;
  margin-bottom: 5px;
  font-size: 14px;
}
.content .title .desc[data-v-304c6f0e] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  margin-top: 7px;
  font-weight: 700;
}
.content .guanzhu[data-v-304c6f0e] {
  /* padding: 0 15px; */
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin-top: 25px;
  border-radius: 5px;
  color: #afafaf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.link[data-v-304c6f0e] {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.link .link-btn[data-v-304c6f0e] {
  width: 49%;
  display: block;
  line-height: 50px;
  line-height: 50px;
  text-align: center;
  color: #656565;
  background-color: #f9f9f9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
.order-table[data-v-304c6f0e] {
  /* border: 1px solid #eae9ed; */
}
.order-table > div[data-v-304c6f0e] {
  /* display: flex; */
  /* flex-wrap:wrap; */
  /* border-bottom: 1px solid #eae9ed; */
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.order-table > div .left[data-v-304c6f0e] {
  /* border-right: 1px solid #eae9ed; */
  width: 100px;
  padding: 10px 0;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  color: #939393;
}
.order-table > div .right[data-v-304c6f0e] {
  /* flex: 1; */
  padding: 10px 0;
  padding-left: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  word-wrap: break-word;
  word-break: normal;
  float: left;
  /* border-left: 1px solid #eae9ed; */
  width: calc(100% - 100px);
  /* font-weight: 600; */
}
.order-table > div[data-v-304c6f0e]:nth-child(2n+1) {
  /* background-color: #f2f2f2; */
}
.options[data-v-304c6f0e] {
  margin-top: 40px;
  /* background-color: pink; */
  font-size: 16px;
  padding-bottom: 30px;
}
.options .options-title[data-v-304c6f0e] {
  font-size: 17px;
  /* font-weight: 600; */
}
.submit-btn[data-v-304c6f0e] {
  color: #fff;
  background-color: #1989fa;
  border: 1px solid #1989fa;
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding: 0 15px;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
}