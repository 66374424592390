.nav[data-v-0296751a] {
  height: 50px;
  /* background-color: #f1f3f4; */
  line-height: 50px;
  position: relative;
  margin-bottom: 10px;
}
.nav .nav-back[data-v-0296751a] {
  position: absolute;
  /* left: 10px; */
  top: 0;
  cursor: pointer;
  font-size: 14px;
}
.nav .nav-title[data-v-0296751a] {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}