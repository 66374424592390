#login-btn[data-v-0296751a] {
  padding: 0 20px;
}
.wx-btn[data-v-0296751a],
.ali-btn[data-v-0296751a] {
  text-align: center;
  width: 100%;
  border-color: #44b549;
  background-color: #358b98;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #44b549), to(#44b549));
  background-image: linear-gradient(to bottom, #44b549 0, #44b549 100%);
  color: #fff;
  display: inline-block;
  overflow: visible;
  padding: 0 23px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin-top: 100px;
}
.ali-btn[data-v-0296751a] {
  margin-top: 30px;
  background: -webkit-gradient(linear, left top, right top, from(#1677ff), to(#1a57ff));
  background: linear-gradient(90deg, #1677ff, #1a57ff);
  border-color: #1677ff;
}