#serveDead[data-v-15e046d6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dead-img[data-v-15e046d6] {
  /* width: 60%; */
  max-width: 230px;
}
h1[data-v-15e046d6] {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
}
h2[data-v-15e046d6] {
  color: #333333;
  margin-top: 2em;
  font-size: 16px;
  line-height: 1.7;
  width: 70%;
  /* text-indent: 2em; */
  text-align: center;
}