.paySuccess-box[data-v-0c56c8de] {
  padding: 15px 3% 0;
  background-color: #f4f4f4;
  min-height: 100vh;
}
.header[data-v-0c56c8de] {
  text-align: center;
  padding: 20px 0 35px;
  background-color: #f4f4f4;
}
.header img[data-v-0c56c8de] {
  width: 30px;
  height: 30px;
  vertical-align: -9px;
  margin-right: 10px;
}
.header span[data-v-0c56c8de] {
  font-weight: 600;
  font-size: 18px;
}
.banner[data-v-0c56c8de] {
  width: 100%;
  height: 100px;
  border-radius: 10px;
}
.order-detail[data-v-0c56c8de] {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 8px;
}
.order-detail > div[data-v-0c56c8de]:first-child {
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 5px;
}
.order-detail > div[data-v-0c56c8de]:nth-child(n+2) {
  color: #656565;
  font-size: 14px;
  line-height: 30px;
}
.order-detail2[data-v-0c56c8de] {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 8px;
}
.dashed[data-v-0c56c8de] {
  width: 100%;
  height: 0;
  border-bottom: 1px dashed #cbcbcb;
  margin: 5px 0;
}
.discount[data-v-0c56c8de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.discount-sum .title[data-v-0c56c8de] {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
.discount-sum .price[data-v-0c56c8de] {
  color: red;
  font-size: 16px;
}
.gongZhongHao[data-v-0c56c8de] {
  padding: 5px 10px 10px 5px;
  color: #b2b2b2;
}
.gongZhongHao .title-desc[data-v-0c56c8de] {
  font-size: 11px;
}
.gongZhongHao .content[data-v-0c56c8de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.gongZhongHao .content .logo[data-v-0c56c8de] {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}
.gongZhongHao .content .logo img[data-v-0c56c8de] {
  width: 40px;
  height: 40px;
}
.gongZhongHao .content .title[data-v-0c56c8de] {
  min-width: 60%;
  margin-left: 8px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.gongZhongHao .content .title .name[data-v-0c56c8de] {
  margin-bottom: 5px;
  font-size: 12px;
}
.gongZhongHao .content .title .desc[data-v-0c56c8de] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}
.gongZhongHao .content .guanzhu[data-v-0c56c8de] {
  padding: 0 7px;
  color: #fff;
  /* border: 1px solid #1aad19; */
  background-image: -webkit-gradient(linear, left top, right top, from(#4186ed), to(#0bb9fb));
  background-image: linear-gradient(to right, #4186ed, #0bb9fb);
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  margin-top: 20px;
  border-radius: 5px;
}
.gongZhongHao .content .guanzhu2[data-v-0c56c8de] {
  padding: 0 7px;
  color: #2c53fc;
  border: 1px solid #2c53fc;
  /* border-image: linear-gradient(#5994ef, #31c4fc) 2 2 2 2; */
  /* background-image: linear-gradient(to right,#4186ed,#0bb9fb); */
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  margin-top: 20px;
  border-radius: 5px;
}
.order-detail1[data-v-0c56c8de] {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 8px;
}
.order-detail1 .orderInfo[data-v-0c56c8de] {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.order-detail1 .orderInfo-content .orderInfo-content-item[data-v-0c56c8de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  line-height: 35px;
  height: 35px;
}
.order-detail1 .orderInfo-content .orderInfo-content-item .left[data-v-0c56c8de] {
  color: #777777;
}
.order-detail1 .orderInfo-content .orderInfo-content-item .right[data-v-0c56c8de] {
  color: #323232;
}
.order-detail1 .orderInfo-content .orderInfo-content-item2[data-v-0c56c8de] {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.order-detail1 .orderInfo-content .orderInfo-content-item2 .left[data-v-0c56c8de] {
  color: #777777;
  width: 80px;
}
.order-detail1 .orderInfo-content .orderInfo-content-item2 .right[data-v-0c56c8de] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  color: #777777;
  /* text-align: right; */
}
.my-btns-box[data-v-0c56c8de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 16px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.my-btns-box .my-btns[data-v-0c56c8de] {
  width: 100px;
  background-color: #2c53fc;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 6px 8px;
  margin-top: 20px;
}
.my-btns-box .watchOrder[data-v-0c56c8de] {
  width: 100px;
  background-color: #2c53fc;
  color: #fff;
}