.zchCountryEmpty[data-v-5602bc59] {
  text-align: center;
}
.zchCountryEmpty > div[data-v-5602bc59] {
  width: 50%;
  margin: 0 auto 1em;
}
.zchCountryEmpty p[data-v-5602bc59] {
  color: #808080;
  font-size: 14px;
  margin-bottom: 0.8em;
}