@charset "UTF-8";
.all .coupon .not-use[data-v-005d962e] {
  height: 10%;
  width: 100%;
  background-color: #f2f2f2; /* 修改背景颜色 */
  font-size: 14px;
  margin-top: 10px 0;
  margin-top: 43px;
}
.all .coupon .not-use .nav[data-v-005d962e] {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #cec9c9;
}
.all .coupon .not-use .nav .all[data-v-005d962e] {
  width: 15%;
  text-align: center;
  background-color: #ffffff; /* 修改背景颜色 */
  padding: 5px;
  border-radius: 10px; /* 圆角 */
  margin-right: 12px;
  margin-left: 10px;
}
.all .coupon .not-use .nav .be-common[data-v-005d962e] {
  width: 15%;
  text-align: center;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 10px; /* 圆角 */
  margin-right: 12px;
}
.all .coupon .not-use .nav .hf[data-v-005d962e] {
  width: 15%;
  text-align: center;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 10px; /* 圆角 */
  margin-right: 12px;
}
.all .coupon .not-use .nav .ll[data-v-005d962e] {
  width: 15%;
  text-align: center;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 10px; /* 圆角 */
  margin-right: 12px;
}
.acquiesce[data-v-005d962e] {
  padding: 10px;
  color: black;
  border-radius: 10px;
}
.selected[data-v-005d962e] {
  color: #ff1e04;
  border: 1px solid #ff1e04;
}
.hend-carousel-map[data-v-005d962e] {
  width: 90%;
  margin: auto;
  overflow: hidden;
}
.hend-carousel-map .my-swipe .van-swipe-item[data-v-005d962e] {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 90px;
}
.hend-carousel-map .my-swipe .van-swipe-item img[data-v-005d962e] {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover; /*  自动拉升 */
}
.first-nav-button[data-v-005d962e] {
  font-size: 22px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.717);
  text-align: center;
  padding: 10px;
  z-index: 9999;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); 添加阴影以示突出 */
}
.first-nav-button .select[data-v-005d962e] {
  color: black;
  font-weight: bold;
}
.first-nav-button .select span[data-v-005d962e] {
  border-bottom: 3px solid #ff1e04;
}
.first-nav-button .notuse-nav[data-v-005d962e] {
  float: left;
  margin-left: 25%;
  text-align: center;
}
.first-nav-button .lose-nav[data-v-005d962e] {
  margin-right: 25%;
  float: right;
}
.bottom-Records[data-v-005d962e] {
  font-size: 22px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: black;
  text-align: center;
  padding: 10px;
  padding-bottom: 40px;
  z-index: 9999;
  -webkit-box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); /* 添加阴影以示突出 */
}