#networkError[data-v-5f241aad] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #999;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#networkError svg[data-v-5f241aad] {
  font-size: 2.6666666667rem;
}
#networkError h2[data-v-5f241aad] {
  font-size: 0.48rem;
  margin-top: 1.44rem;
}