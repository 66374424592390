.codePayPopup .qrcode-bottom[data-v-479b99cf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.codePayPopup[data-v-479b99cf] {
  padding: 12px 30px;
  font-size: 20px;
  border-radius: 4px;
}
.codePayPopup > h1[data-v-479b99cf] {
  font-size: 26px;
}
.codePayPopup > h3[data-v-479b99cf] {
  background-color: #e31613;
  color: #fff;
  display: inline-block;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 12px;
}
.codePayPopup > p[data-v-479b99cf] {
  font-size: 13px;
  margin-top: 20px;
}
.codePayPopup > p em[data-v-479b99cf] {
  display: inline-block;
  color: #e31613;
}
.codePayPopup > p em .countDown[data-v-479b99cf] {
  color: #e31613;
}
.codePayPopup .qrcode-img[data-v-479b99cf] {
  width: 10rem;
  margin: 20px 0;
  border: 1px solid #ddd;
}
.codePayPopup .qrcode-bottom[data-v-479b99cf] {
  background-color: #108EE8;
  border-radius: 4px;
  padding: 8px 0;
}
.codePayPopup .qrcode-bottom svg[data-v-479b99cf] {
  font-size: 44px;
  fill: #fff;
}
.codePayPopup .qrcode-bottom p[data-v-479b99cf] {
  line-height: 1.6;
  color: #fff;
  font-weight: 700;
  margin-left: 2em;
  font-size: 15px;
}
@media (max-width: 768px) {
.codePayPopup .qrcode-img[data-v-479b99cf] {
    width: 7rem;
}
}