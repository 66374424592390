
.fixed-button[data-v-ea63ece2] {
  position: fixed;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  background-color: #f6f6f6; /*  背景 */
  color: black; /* 黑色字体 */
  text-align: center; /* 字体居中 */
  padding: 10px 0; /* 添加一些内边距以增强按钮的视觉效果 */
  border-radius: 4px; /* 可选：圆角效果 */
  font-size: 25px;
}
