.timer[data-v-1f44ad08] {
  overflow: hidden;
  height: 100vh;
  background-color: #E1DFE2;
}
.saveButton[data-v-1f44ad08] {
  font-size: 15px;
  text-align: center;
  line-height: 10vh;
  /* position: absolute;
  bottom: 30px;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%); */
}
.saveButton span[data-v-1f44ad08] {
  /* color: yellow; */
  /* background-color: yellow; */
  padding: 12px 20px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}
.my-swipe .van-swipe-item[data-v-1f44ad08] {
  color: #fff;
  font-size: 20px;
  /* line-height: 90vh; */
  text-align: center;
  background-color: #e1dfe2;
}
img[data-v-1f44ad08] {
  /* width: 100vw; */
  height: 90vh;
  vertical-align: middle;
}
.custom-indicator[data-v-1f44ad08] {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.leftArrow[data-v-1f44ad08] {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.leftArrow[data-v-1f44ad08]::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-29%, -50%) rotate(45deg);
          transform: translate(-29%, -50%) rotate(45deg);
}
.rightArrow[data-v-1f44ad08] {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.rightArrow[data-v-1f44ad08]::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-70%, -50%) rotate(225deg);
          transform: translate(-70%, -50%) rotate(225deg);
}