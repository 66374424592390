@charset "UTF-8";
.pinMi[data-v-237db481] {
  content: attr(data-hotTips);
  position: absolute;
  padding: 0.4em 0.7em;
  border-radius: 1.8em;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  color: #212121;
  font-size: 10px;
  background-color: rgb(244, 201, 2);
  right: -0.44em;
  top: -1em;
}
.productItem[data-v-237db481] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; /* 让 item 充满父容器高度 */
  width: 31.3%;
  max-width: 114px;
  min-width: 114px;
  color: #0c7ad6;
  border: 1px solid #0c7ad6;
  border-radius: 5px;
  margin: 0 1% 14px;
}
.productItem.active[data-v-237db481] {
  background-color: #0c7ad6;
  border: 1px solid #0c7ad6;
  color: #fff;
}
.productItem.disabled[data-v-237db481] {
  border: 1px solid #ddd;
  color: #ddd;
  pointer-events: none;
}
.productItem em[data-v-237db481] {
  font-weight: bold;
}
.productItem b[data-v-237db481] {
  font-size: 9px;
}
.productItem p[data-v-237db481] {
  margin-top: 8px;
  font-size: 12px;
}
.productItem.hot[data-v-237db481]::after {
  content: attr(data-hotTips);
  position: absolute;
  padding: 0.4em 0.7em;
  border-radius: 1.8em;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 120, 46)), to(rgb(255, 78, 71)));
  background-image: linear-gradient(90deg, rgb(255, 120, 46), rgb(255, 78, 71));
  right: -0.44em;
  top: -1em;
}
.productItem.firstDiscount[data-v-237db481]::after {
  content: attr(data-hotTips);
  position: absolute;
  padding: 0.4em 0.7em;
  border-radius: 1.8em;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f54a00), to(#fcbf0b));
  background-image: linear-gradient(90deg, #f54a00, #fcbf0b);
  right: -0.44em;
  top: -1.3em;
}
.productItem .grayLineThrough[data-v-237db481] {
  color: gray;
  font-size: 9px;
  text-decoration: line-through;
}
.productItem .name[data-v-237db481] {
  margin-top: 10px;
}
.productItem.paddingAdd[data-v-237db481] {
  padding-bottom: 5px;
}
.productItem .grayLine[data-v-237db481] {
  color: gray;
}
.productItem .redFont[data-v-237db481] {
  color: red;
  font-size: 13px;
}
.productItem .preferentialPrice[data-v-237db481] {
  margin-top: 5px;
  color: red;
  font-size: 13px;
  position: relative;
  top: -5px;
}