.paySuccess[data-v-481bcec1] {
  text-align: center;
}
.paySuccess .paySuccessImg[data-v-481bcec1] {
  width: 80px;
  height: 80px;
  margin: 30px 0 20px;
}
.paySuccess h2[data-v-481bcec1] {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.paySuccess .desc[data-v-481bcec1] {
  font-size: 14px;
  color: #7d7d7d;
  margin-bottom: 5px;
}
.paySuccess .detail-btn[data-v-481bcec1] {
  font-size: 20px;
  color: #3250d5;
  border: 1px solid #3250d5;
  padding: 10px 10px;
  border-radius: 22px;
  width: 208px;
  margin: 30px auto 0;
}