@charset "UTF-8";
.list-item[data-v-fb9693c6] {
  height: 300px;
  float: left;
  width: 48%;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 1%;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  overflow: hidden; /* 隐藏溢出的文本 */
}
.cover-image[data-v-fb9693c6] {
  width: 100%;
  height: 220px;
  background-color: #192;
  margin: 0 auto;
  margin-bottom: 10px;
  -o-object-fit: cover;
     object-fit: cover; /* 确保图片按照容器比例展示，不变形 */
}
.content .title[data-v-fb9693c6] {
  font-size: 16px;
  margin: 5%;
  width: 90%;
  overflow: hidden; /* 隐藏溢出的文本 */
  display: -webkit-box; /* 使用 flexbox 布局 */
  -webkit-box-orient: vertical; /* 垂直排列子元素 */
  -webkit-line-clamp: 3; /* 限制显示的行数 */
  white-space: normal; /* 正常的换行 */
  text-overflow: ellipsis; /* 使用省略号代替溢出的文本 */
}