#shareQrCode[data-v-54b79ea0] {
  /* height: 100vh; */
  /* background-color: #000; */
  font-size: 16px;
}
img[data-v-54b79ea0] {
  width: 100%;
}
.desc[data-v-54b79ea0] {
  /* color: #fff; */
  text-align: center;
  line-height: 30px;
}