@charset "UTF-8";
.rechargeBar-content-empty-tips[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flowProductDetail[data-v-664e340e], .rechargeBar-content[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar-content[data-v-664e340e] {
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 19px 3%;
  position: relative;
}
.rechargeBar-content-empty[data-v-664e340e] {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
}
.rechargeBar-content-empty-tips[data-v-664e340e] {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 1;
  color: #979797;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.rechargeBar-content-empty-tips svg[data-v-664e340e] {
  color: #0c7ad6;
  margin-right: 0.4em;
}
.rechargeBar-content-empty > div[data-v-664e340e] {
  width: 50%;
  margin: 0 auto 1em;
}
.rechargeBar-content-empty p[data-v-664e340e] {
  color: #808080;
  font-size: 14px;
  margin-bottom: 0.8em;
}
.flowProductDetail[data-v-664e340e] {
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding: 20px 3%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flowProductDetail-info[data-v-664e340e] {
  padding-right: 0.4em;
}
.flowProductDetail-info > p[data-v-664e340e] {
  font-size: 18px;
  color: #0e0e0e;
  font-weight: bold;
  margin-bottom: 10px;
}
.flowProductDetail-info > p em[data-v-664e340e] {
  font-size: 12px;
  color: #108ee8;
  border: 1px solid;
  padding: 4px 6px;
  font-weight: normal;
  vertical-align: 0.2em;
  border-radius: 3px;
}
.flowProductDetail-info > span[data-v-664e340e] {
  color: #737373;
  font-size: 12px;
}
.flowProductDetail-buy > p[data-v-664e340e] {
  color: #ff4e47;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}
.flowProductDetail-buy > div[data-v-664e340e] {
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  background-color: #108ee8;
  border-radius: 5px;
  padding: 7px 13px;
  margin-top: 12px;
  white-space: nowrap;
}
.line_class[data-v-664e340e] {
  width: 100% !important;
  text-align: left;
  font-size: 18px;
  font-weight: 800;
  color: #343434;
  margin-bottom: 13px;
  margin-left: 10px;
}
.flowicon[data-v-664e340e] {
  height: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  margin: 0 auto;
  color: yellow;
}
.point[data-v-664e340e] {
  width: 100% !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 13px;
  margin-left: 10px;
}
.productList[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}
.productList-day[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}
.productList-week[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}
.productList-mouth[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}
.productList-other[data-v-664e340e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}