.listTitle[data-v-462c85de],
.listItem[data-v-462c85de], header[data-v-462c85de] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.shareRecords[data-v-462c85de] {
  min-height: 100vh;
  padding-bottom: 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
header[data-v-462c85de] {
  text-align: center;
  color: #fff;
  font-size: 12px;
  background: -webkit-gradient(linear, left top, right top, from(rgb(110, 47, 255)), to(rgb(61, 165, 255)));
  background: linear-gradient(90deg, rgb(110, 47, 255), rgb(61, 165, 255));
  -webkit-box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 96%;
  margin: 11px auto;
  padding: 28px 0;
}
header em[data-v-462c85de] {
  font-size: 24px;
  font-weight: bold;
}
header em span[data-v-462c85de] {
  font-size: 12px;
  font-weight: normal;
}
header p[data-v-462c85de] {
  margin-top: 12px;
}
header .friend[data-v-462c85de] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-right: 1px solid #fff;
}
header .point[data-v-462c85de] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.listTitle[data-v-462c85de],
.listItem[data-v-462c85de] {
  text-align: center;
  padding: 15px 0;
}
.listTitle p[data-v-462c85de],
.listItem p[data-v-462c85de] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.listTitle[data-v-462c85de] {
  background-color: #f9f9f9;
  color: #222222;
  font-size: 15px;
  font-weight: bold;
}
.listItem[data-v-462c85de] {
  border-bottom: 1px solid #f2f2f3;
  color: #808080;
  font-size: 12px;
}
.listItem-empty[data-v-462c85de] {
  text-align: center;
  color: #808080;
  font-size: 12px;
  padding-top: 2em;
}
footer[data-v-462c85de] {
  color: #fff;
  font-size: 15px;
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  padding: 18px 0;
  background-color: #ff732a;
  text-align: center;
  max-width: 750px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}