#Page404[data-v-18f4c7ca] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.img-404[data-v-18f4c7ca] {
  width: 60%;
}
h1[data-v-18f4c7ca] {
  font-size: 0.4rem;
  font-weight: bold;
  color: rgb(51, 51, 51);
  line-height: 3;
  margin-top: 2em;
}
h2[data-v-18f4c7ca] {
  color: #C9C9C9;
  font-size: 0.36rem;
  margin-bottom: 9em;
}
.return-button[data-v-18f4c7ca] {
  padding: 0 5em;
  color: #8F8F8F;
}