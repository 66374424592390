.month_bar[data-v-05bbef40] {
  font-size: 10px;
  background-color: #f5f5f5;
  padding: 12px 15px;
  position: sticky;
  z-index: 2;
  top: 0;
}
.month_bar > span[data-v-05bbef40] {
  display: inline-block;
  background-color: #fff;
  border-radius: 1em;
  padding: 0.3em 0.8em;
  color: #333;
}
.month_bar svg[data-v-05bbef40] {
  vertical-align: 0;
  margin-left: 0.4em;
  font-size: 8px;
}
.month_bar > em[data-v-05bbef40] {
  color: #aaa;
  margin-left: 1em;
}
.order_list_item[data-v-05bbef40] {
  border-bottom: 0.24rem solid #f5f5f5;
  padding: 14px 14px 10px;
}
.order_list_item .item_info[data-v-05bbef40] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}
.order_list_item .item_info > svg[data-v-05bbef40] {
  font-size: 52px;
  margin-right: 12px;
}
.order_list_item .item_info > svg.HF[data-v-05bbef40], .order_list_item .item_info > svg.POSTPAID[data-v-05bbef40] {
  fill: #1ac150;
}
.order_list_item .item_info > svg.LL[data-v-05bbef40] {
  fill: #ef8b0a;
}
.order_list_item .item_info .item_info_price_time[data-v-05bbef40] {
  font-size: 13px;
  color: #222222;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 2px;
}
.order_list_item .item_info .item_info_price_time > em[data-v-05bbef40] {
  font-weight: bold;
}
.order_list_item .item_info .item_info_price_time p[data-v-05bbef40] {
  margin: 4px 0;
  font-size: 11px;
  color: #999;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.order_list_item .item_info .item_info_price_time > small[data-v-05bbef40] {
  font-size: 10px;
  margin-top: 10px;
  color: #aaa;
}
.order_list_item .item_info .item_info_state[data-v-05bbef40] {
  text-align: right;
  font-size: 13px;
  width: 4.6em;
  padding-top: 2px;
}
.order_list_item .item_info .item_info_state p[data-v-05bbef40] {
  color: #282828;
  margin-top: 5px;
  font-size: 11px;
  text-align: center;
}
.order_list_item .order_price[data-v-05bbef40] {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  border-top: 1px solid #f0edf1;
  color: #262626;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.order_list_item .order_price span[data-v-05bbef40] {
  font-weight: normal;
  font-size: 11px;
  padding-right: 10px;
}
.order_list_item .order_price em[data-v-05bbef40] {
  padding: 0.5em 0.6em;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
}
.order_list_item .order_price em.pay[data-v-05bbef40] {
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 225, 78)), to(rgb(255, 196, 78)));
  background-image: linear-gradient(to right, rgb(255, 225, 78), rgb(255, 196, 78));
}
.order_list_item .order_price em.close[data-v-05bbef40] {
  background-image: -webkit-gradient(linear, left top, right top, from(#f95668), to(#ee0a24));
  background-image: linear-gradient(to right, #f95668, #ee0a24);
  margin-left: 4px;
}
.order_list .PinButton[data-v-05bbef40] {
  margin-top: 30px;
  margin-right: 2px;
  font-size: 12px;
  /* border-top: 1px solid rgb(240, 237, 241); */
  color: white;
  font-weight: bold;
  padding: 0.3em 0.2em;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, right top, from(#fe6947), to(#fabf2b));
  background-image: linear-gradient(to right, #fe6947, #fabf2b);
  text-align: center;
}
.pinCode[data-v-05bbef40] {
  word-break: break-all;
  overflow-wrap: break-word;
  font-size: 14px;
  background-color: whitesmoke;
  border-radius: 0.4em;
  text-align: center;
}
.copyButton[data-v-05bbef40] {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background-color: #ff7772;
  color: #fff;
  border: none;
  border-radius: 0.4em;
}
.rechargeMethod[data-v-05bbef40] {
  color: rgba(109, 109, 18, 0.85);
  font-size: 14px;
  word-break: break-all;
  word-wrap: break-word;
  background-color: whitesmoke;
  border-radius: 0.4em;
  padding: 2px 3px;
}
.box-content[data-v-05bbef40] {
  font-size: 12px;
  color: #6d6d6d;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-direction: column; */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 8px 16px;
  padding: 8px 0px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.box[data-v-05bbef40] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #257ae7;
  color: #fff;
}
.item-card2[data-v-05bbef40] {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0 8px 10px;
  /* display: flex;
    justify-content: space-between; */
}
.item-card2 .header[data-v-05bbef40] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}
.item-card2 .content[data-v-05bbef40] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
}
.item-card2 .content .content-item[data-v-05bbef40] {
  width: 172px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 10px;
}
.item-card2 .content .content-item .img[data-v-05bbef40] {
  height: 220px;
}
.item-card2 .content .content-item .img img[data-v-05bbef40] {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.item-card2 .content .content-item .title[data-v-05bbef40] {
  line-height: 28px;
  min-height: 56px;
  margin-top: 6px;
  font-size: 14px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.skipBtnDetail[data-v-05bbef40] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin: 8px 16px;
  padding: 8px 0px;
  /* width: 100%; */
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #6d6d6d;
}
.skipBtnDetail .skipBtnDetailIcon[data-v-05bbef40] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}