.saveButton[data-v-e6f9e84c] {
  font-size: 15px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  white-space: nowrap;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.saveButton span[data-v-e6f9e84c] {
  color: yellow;
  /* background-color: yellow; */
  padding: 12px 20px;
  border-radius: 5px;
}
.posterWrapper[data-v-e6f9e84c] {
  overflow: hidden;
  height: 100vh;
  background-color: rgb(2, 50, 95);
}