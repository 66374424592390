@charset "UTF-8";
.pageHeader[data-v-0004aa8c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; /* 元素靠右对齐 */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* 垂直居中 */
  padding-top: 20px;
}
.pageHeader img[data-v-0004aa8c] {
  margin-right: 4px; /* 图标与文字之间的间距缩减一半 */
}
.all[data-v-0004aa8c] {
  /* height: 900px; */
  padding: 15px; /* 缩减一半 */
}
.all .savebutton[data-v-0004aa8c] {
  margin-bottom: 5px; /* 缩减一半 */
}
.all .savebutton button[data-v-0004aa8c] {
  background-color: #007aff;
  /* width: 70%; */
  margin: 0 auto;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-size: 12px; /* 缩减一半 */
  line-height: 8px; /* 缩减一半 */
  padding: 2.5px 2.5px; /* 缩减一半 */
}
.all .ImageAll[data-v-0004aa8c] {
  background-color: #bfbfbf;
  text-align: center;
}
.all .ImageAll .title[data-v-0004aa8c] {
  margin: 15px 0; /* 缩减一半 */
  margin-bottom: 5px; /* 缩减一半 */
  text-align: center;
  font-size: 18px; /* 缩减一半 */
}
.all .ImageAll .table[data-v-0004aa8c] {
  display: table;
  width: 100%;
  border-collapse: collapse; /* 取消边框间隔 */
  margin: 0; /* 确保外部没有多余的间距 */
  padding: 0; /* 单元格内边距 */
}
.all .ImageAll .left[data-v-0004aa8c],
.all .ImageAll .right[data-v-0004aa8c] {
  display: table-cell;
  vertical-align: top;
  /* border: 1px solid #000; */
}
.all .ImageAll .left[data-v-0004aa8c] {
  width: 40%;
  padding-left: 18px; /* 缩减一半 */
}
.all .ImageAll .right[data-v-0004aa8c] {
  width: 60%;
  padding-right: 18px; /* 缩减一半 */
}
.all .ImageAll .one[data-v-0004aa8c] {
  font-size: 8px; /* 缩减一半 */
  border: 0.5px solid #000; /* 边框宽度缩减一半 */
  text-align: center;
  border-top: none;
  height: 25px; /* 固定高度缩减一半 */
  line-height: normal; /* 移除单行高度限制，允许换行 */
  word-wrap: break-word; /* 长词语会被断开换行 */
  white-space: normal; /* 允许文字换行 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; /* 使用 Flexbox 实现居中对齐 */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* 垂直居中 */
}
.all .ImageAll .one[data-v-0004aa8c]:nth-child(odd) {
  background-color: white; /* 奇数项背景设置为白色 */
}
.all .ImageAll .taller[data-v-0004aa8c] {
  height: 40px; /* 自定义的高度缩减一半 */
}
.all .ImageAll .first[data-v-0004aa8c] {
  border-top: 0.5px solid #000; /* 边框宽度缩减一半 */
}
.all .ImageAll .chnelBorder[data-v-0004aa8c] {
  border-left: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; /* 水平居中 */
}
.all .ImageAll .leftpadding[data-v-0004aa8c] {
  padding-left: 8px; /* 缩减一半 */
}
.all .ImageAll .imageBottom[data-v-0004aa8c] {
  padding-bottom: 200px;
  background-color: #bfbfbf;
}