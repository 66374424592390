.card-body[data-v-c230525a], .vipArtBar[data-v-c230525a], .userCard-info-name[data-v-c230525a], .userCard[data-v-c230525a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#userCenter[data-v-c230525a] {
  padding: 10px 15px;
  padding-bottom: 50px;
}
.userCard-img[data-v-c230525a] {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 17.5px;
}
.userCard-info[data-v-c230525a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.userCard-info-name[data-v-c230525a] {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
.userCard-info-name em[data-v-c230525a] {
  max-width: 6em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3;
}
.userCard-info-name span[data-v-c230525a] {
  margin-left: 12px;
  color: #1f222f;
  font-size: 12px;
  padding: 5px 6px;
  background: -webkit-gradient(linear, right top, left top, from(rgb(217, 170, 140)), to(rgb(248, 229, 206)));
  background: linear-gradient(-90deg, rgb(217, 170, 140), rgb(248, 229, 206));
}
.userCard-info-point[data-v-c230525a] {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 4px 14px;
  background: linear-gradient(84deg, rgb(168, 225, 225), rgb(64, 163, 221));
  margin-top: 12px;
  border-radius: 10px;
}
.userCard-sign[data-v-c230525a] {
  background-color: #ffac30;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 6.5px 11.5px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  margin-right: -15px;
}
.userCard-sign svg[data-v-c230525a] {
  font-size: 8px;
  vertical-align: 0;
  margin-left: 0.5em;
}
.vipArtBar[data-v-c230525a] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #000;
  margin-top: 22px;
  /* margin-bottom: 15px; */
}
.vipArtBar-info[data-v-c230525a] {
  font-weight: 400;
  color: #f8e5ce;
  font-size: 12px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgb(217, 170, 140)), color-stop(49.462890625%, rgb(248, 229, 206)));
  background: linear-gradient(0deg, rgb(217, 170, 140) 0%, rgb(248, 229, 206) 49.462890625%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vipArtBar-info > p[data-v-c230525a] {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 8px;
}
.vipArtBar-info > p svg[data-v-c230525a] {
  font-size: 13px;
  margin-right: 6px;
}
.vipArtBar-button p[data-v-c230525a] {
  color: #1d222f;
  padding: 5px 9px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 44px;
  background: -webkit-gradient(linear, right top, left top, from(rgb(217, 170, 140)), to(rgb(248, 229, 206)));
  background: linear-gradient(-90deg, rgb(217, 170, 140), rgb(248, 229, 206));
}
.vipArtBar-button svg[data-v-c230525a] {
  font-size: 20px;
  fill: #f8e5ce;
}
.card[data-v-c230525a] {
  border-radius: 5px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 15px 5px rgba(203, 203, 203, 0.22);
          box-shadow: 0px 0px 15px 5px rgba(203, 203, 203, 0.22);
  padding: 15px 10px;
  margin: 10px 0;
}
.card-title[data-v-c230525a] {
  color: #000000;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.card-title span[data-v-c230525a] {
  color: #13a5f8;
  font-size: 12px;
  font-weight: normal;
}
.card-body[data-v-c230525a] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-body-item[data-v-c230525a] {
  text-align: center;
}
.card-body-item em[data-v-c230525a] {
  font-size: 18px;
  color: #323232;
  font-weight: bolder;
}
.card-body-item p[data-v-c230525a] {
  color: #3e3e3e;
  font-size: 12px;
  margin-top: 11px;
  margin-bottom: 4px;
}
.serveCard .card-body-item[data-v-c230525a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.serveCard .card-body-item svg.message_fill[data-v-c230525a] {
  fill: rgb(66, 190, 252);
}
.serveCard .card-body-item svg.smile_fill[data-v-c230525a] {
  fill: rgb(245, 129, 62);
}
.fansGroupPopup[data-v-c230525a] {
  background-color: transparent;
  /* width: 74%; */
}
.miniprogramPopup[data-v-c230525a] {
  width: 92%;
  background-image: linear-gradient(-235deg, rgb(59, 75, 255), rgb(53, 68, 254), rgb(53, 68, 254));
  border-radius: 10px;
  padding: 23px 18px 20px;
}
.gongZhongHao[data-v-c230525a] {
  border: 1px solid #e5e5e5;
  padding: 5px 10px 10px 5px;
  color: #b2b2b2;
  margin-top: 15px;
}
.gongZhongHao .title-desc[data-v-c230525a] {
  font-size: 11px;
}
.gongZhongHao .content[data-v-c230525a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.gongZhongHao .content .logo[data-v-c230525a] {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}
.gongZhongHao .content .logo img[data-v-c230525a] {
  width: 50px;
  height: 50px;
}
.gongZhongHao .content .title[data-v-c230525a] {
  min-width: 60%;
  margin-left: 8px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.gongZhongHao .content .title .name[data-v-c230525a] {
  color: #000;
  margin-bottom: 5px;
  font-size: 16px;
}
.gongZhongHao .content .title .desc[data-v-c230525a] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-top: 7px;
}
.gongZhongHao .content .guanzhu[data-v-c230525a] {
  padding: 0 15px;
  color: #1aad19;
  border: 1px solid #1aad19;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 5px;
}
.gzhImgStyle[data-v-c230525a] {
  padding: 0 !important;
}

/* ::v-deep .van-dialog__message{
    padding: 0!important;
  } */