@charset "UTF-8";
.item-card[data-v-293d4c26] {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0px 8px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item-card .left[data-v-293d4c26] {
  /* flex: 1; */
  width: calc(100% - 110px);
  max-width: 500px;
}
.item-card .left .left-top[data-v-293d4c26] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item-card .left .left-top .title[data-v-293d4c26] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}
.item-card .left .left-top .unitValidity[data-v-293d4c26] {
  padding: 4px 7px;
  background-color: #ff464a;
  color: #fff;
  border-radius: 6px;
  margin-left: 10px;
}
.item-card .left .desc[data-v-293d4c26] {
  line-height: 22px;
  font-size: 14px;
  color: #6d6d6d;
  margin-top: 4px;
  word-wrap: break-word;
  word-break: normal;
  min-height: 44px;
}
.item-card .right[data-v-293d4c26] {
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}
.box-content[data-v-293d4c26] {
  margin: 0 10px;
  font-size: 12px;
  color: #6d6d6d;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.box[data-v-293d4c26] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #257ae7;
  color: #fff;
}
.item-card2[data-v-293d4c26] {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0 8px 10px;
  /* display: flex;
        justify-content: space-between; */
}
.item-card2 .header[data-v-293d4c26] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}
.item-card2 .content[data-v-293d4c26] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
}
.item-card2 .content .content-item[data-v-293d4c26] {
  width: 172px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 10px;
}
.item-card2 .content .content-item .img[data-v-293d4c26] {
  height: 220px;
}
.item-card2 .content .content-item .img img[data-v-293d4c26] {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.item-card2 .content .content-item .title[data-v-293d4c26] {
  line-height: 28px;
  min-height: 56px;
  margin-top: 6px;
  font-size: 14px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ispc[data-v-293d4c26] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.fansGroupPopup[data-v-293d4c26] {
  background-color: transparent;
  /* width: 74%; */
}
.isCenter[data-v-293d4c26] {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
.point[data-v-293d4c26] {
  width: 100% !important;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 10px;
}
.carouselMap[data-v-293d4c26] {
  width: 90%;
  max-height: 200px;
}
.more .more-button[data-v-293d4c26] {
  display: inline-block;
  padding: 15px 25px;
  margin: 5px 0;
  background-color: #f1f4f8;
  border-radius: 50px;
}
.custom-indicator[data-v-293d4c26] {
  position: absolute;
  top: 10px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
  background: #6d6d6d; /* 可选：背景颜色和透明度 */
  color: white; /* 文字颜色 */
  padding: 5px 10px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  font-size: 14px; /* 字体大小 */
}