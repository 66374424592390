
.countryInput-top-phoneList .delete-all-phoneList[data-v-1d9b105c] {
  height: 35px;
  /* background-color: skyblue; */

  position: relative;
  top: 10px;
}
.countryInput-top-phoneList .delete-all-phoneList span[data-v-1d9b105c] {
  text-align: center;
  line-height: 35px;
  color: #e93223;
  font-size: 18px;
  display: block;
  font-weight: 500;
}
