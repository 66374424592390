@charset "UTF-8";
.leaderboard-content[data-v-73146604] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#shareFriends[data-v-73146604] {
  background: -webkit-gradient(linear, left top, left bottom, from(#0062eb), to(#0095f4));
  background: linear-gradient(to bottom, #0062eb, #0095f4);
}
.image-container[data-v-73146604] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; /* 水平居中 */
}
.image-container img[data-v-73146604] {
  margin-top: 50px;
  width: 80%; /* 将图片缩小为原大小的 80%，可以根据需要调整 */
}
.contentWrapper[data-v-73146604] {
  background-color: #1861ca;
  /* padding: 4%; */
  padding-bottom: 30px;
}
.shareTipsWrapper[data-v-73146604] {
  position: relative;
}
.shareButtonBar[data-v-73146604] {
  position: absolute;
  top: -300px;
  /* top: -40%; */
  left: 0;
  width: 100%;
  text-align: center;
}
.shareButtonBar p[data-v-73146604] {
  text-align: right;
}
.shareButtonBar p a[data-v-73146604] {
  color: #fff;
  font-size: 12px;
  padding: 5px 3px 5px 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-image: linear-gradient(49deg, rgb(253, 143, 101), rgb(249, 87, 73));
  border: 2px solid #fede27;
  border-right: none;
}
.shareButtonBar button[data-v-73146604] {
  color: #ed403a;
  font-size: 15px;
  font-weight: bold;
  background-color: #fede27;
  padding: 0.8em 2.6em;
  border-radius: 3em;
  border: none;
  margin-top: 20px;
  -webkit-box-shadow: 0 0 10px 2px rgba(31, 15, 130, 0.5) inset;
          box-shadow: 0 0 10px 2px rgba(31, 15, 130, 0.5) inset;
  -webkit-animation: scale-73146604 0.5s linear infinite alternate;
          animation: scale-73146604 0.5s linear infinite alternate;
}
.shareButtonBar2[data-v-73146604] {
  top: -50%;
}
@-webkit-keyframes scale-73146604 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
}
@keyframes scale-73146604 {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
}
.shareTips[data-v-73146604] {
  background-color: #fff;
  border-bottom: 24px solid rgb(24, 97, 202);
  margin-top: -42px;
  border: 7px solid #9fdef4;
  border-radius: 15px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fdfeff), to(#cff0ff));
  background-image: linear-gradient(to bottom, #fdfeff, #cff0ff);
  width: 96%;
  margin: 15px auto 0;
}
.shareTips2[data-v-73146604] {
  margin-top: 30px;
}
.shareTips2-title[data-v-73146604] {
  margin-top: 10px;
  text-align: center;
  position: relative;
  font-size: 16px;
  font-weight: 600;
}
.shareTips2-title[data-v-73146604]::before {
  content: "";
  border: 1px solid #989999;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  -webkit-transform: translate(-200%, -50%);
          transform: translate(-200%, -50%);
  background-color: #989999;
}
.shareTips2-title[data-v-73146604]::after {
  content: "";
  border: 1px solid #989999;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  background-color: #989999;
}
.leaderboard[data-v-73146604] {
  background-color: #fff;
  padding: 0 34px 24px;
}
.leaderboard-title[data-v-73146604] {
  font-size: 18px;
  color: #474448;
  text-align: center;
  padding: 18px;
  font-weight: bold;
}
.leaderboard-content[data-v-73146604] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 15px;
  color: #474448;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
  font-weight: bold;
}
.leaderboard-content svg[data-v-73146604] {
  margin-right: 0.3em;
  font-size: 22px;
  vertical-align: -0.2em;
}
.leaderboard-content svg.ranking1[data-v-73146604] {
  fill: rgb(255, 215, 38);
}
.leaderboard-content svg.ranking2[data-v-73146604] {
  fill: rgb(215, 215, 215);
}
.leaderboard-content svg.ranking3[data-v-73146604] {
  fill: rgb(253, 153, 29);
}
.leaderboard-content span[data-v-73146604] {
  color: #6d6b6e;
  font-size: 12px;
  font-weight: normal;
}
.leaderboard-tips[data-v-73146604] {
  padding: 14px 34px;
  background-color: #f3f1ff;
  color: #3d2aae;
  font-size: 12px;
  line-height: 1.4;
  border-bottom: 18px solid rgb(63, 41, 176);
}
.invite[data-v-73146604] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  font-size: 26px;
  font-weight: 900;
  margin-top: 30px;
}
.invite-left[data-v-73146604] {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff7c89), to(#f85464));
  background: linear-gradient(to bottom, #ff7c89, #f85464);
  /* background-image: linear-gradient(top,#FF7C89,#FF7381); */
  padding: 15px 45px;
  border-radius: 50px;
  margin: 20px 0;
  /* 添加立体效果 */
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15), inset 0px 5px 10px rgba(255, 255, 255, 0.3);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15), inset 0px 5px 10px rgba(255, 255, 255, 0.3); /* 外部阴影+内部高光 */
  border: 1px solid rgba(255, 255, 255, 0.3); /* 边框增加对比 */
}
.invite-right[data-v-73146604] {
  background-color: #202777;
  padding: 10px 20px;
  border-radius: 30px;
}
.shareTips2-body[data-v-73146604] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shareTips2-record[data-v-73146604] {
  font-size: 12px;
  color: #989999;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.shareTips2-record div[data-v-73146604] {
  border-bottom: 1px solid #989999;
}
.shareTips2-body[data-v-73146604] {
  margin: 25px 30px;
  /* font-weight: 600; */
}
.shareTips2-body .left .title[data-v-73146604] {
  font-size: 14px;
}
.shareTips2-body .left .num[data-v-73146604] {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  color: #fd3f59;
}
.shareTips2-body .left .num .num1[data-v-73146604] {
  font-weight: 600;
}
.shareTips2-body .left .num .num2[data-v-73146604] {
  font-size: 12px;
}
.shareTips2-body .right .title[data-v-73146604] {
  font-size: 14px;
}
.shareTips2-body .right .num[data-v-73146604] {
  font-size: 18px;
  margin-top: 15px;
  color: #fd3f59;
  text-align: center;
}
.shareTips2-body .right .num .num1[data-v-73146604] {
  font-weight: 600;
}
.shareTips2-body .right .num .num2[data-v-73146604] {
  font-size: 12px;
}
.toShop[data-v-73146604] {
  font-size: 12px;
  color: #fd3f59;
  padding: 6px 8px;
  border: 1px solid #fd3f59;
  border-radius: 12px;
  display: block;
  width: 120px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.mask[data-v-73146604] {
  z-index: 100;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
}
.mask .arrow[data-v-73146604] {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 68px;
}
.mask .desc[data-v-73146604] {
  font-size: 16px;
  color: #fff;
  position: fixed;
  top: 120px;
  right: 60px;
  line-height: 30px;
}
.mask .know[data-v-73146604] {
  padding: 10px 20px;
  border: 3px solid #fff;
  border-radius: 10px;
  position: fixed;
  top: 220px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #fff;
  font-size: 16px;
}
.qrImg[data-v-73146604] {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 135px;
  left: 125px;
}
.time[data-v-73146604] {
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 40px;
  width: 70px;
  font-size: 10px;
}
.rule-box[data-v-73146604] {
  padding: 15px 20px;
  font-size: 14px;
}
.rule-box div[data-v-73146604] {
  font-size: 16px;
}
.rule-box p[data-v-73146604] {
  line-height: 25px;
}
.chacha[data-v-73146604] {
  border: 1px solid #ddd;
  position: absolute;
  bottom: -50px;
  left: 50%;
  z-index: 999;
}
.save[data-v-73146604] {
  line-height: 40px;
  font-size: 16px;
  color: #ed403a;
  text-align: center;
}