.payType-item > div[data-v-6e0dd596], .payType-item[data-v-6e0dd596] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.payType[data-v-6e0dd596] {
  font-size: 14px;
  color: #808080;
}
.payType-title[data-v-6e0dd596] {
  color: #808080;
  font-size: 14px;
  margin: 13px 0;
  padding: 0 4%;
}
.payType-item[data-v-6e0dd596] {
  padding: 8px 4%;
  border-top: 1px solid #F2F2F3;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payType-item[data-v-6e0dd596]:last-child {
  border-bottom: 1px solid #F2F2F3;
}
.payType-item svg[data-v-6e0dd596] {
  font-size: 21px;
}
.payType-item p[data-v-6e0dd596] {
  margin-left: 1em;
}