footer[data-v-7754c5eb] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.resultQuery[data-v-7754c5eb] {
  position: fixed;
  left: 50%;
  bottom: 52px;
  width: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 750px;
  text-align: center;
  color: #25a8f5;
  font-size: 14px;
  background-color: #fff;
  line-height: 30px;
  cursor: pointer;
}
footer[data-v-7754c5eb] {
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 750px;
  background-color: #F5F2F0;
  text-align: center;
  font-size: 24px;
  padding: 13px 0;
}
footer > div[data-v-7754c5eb] {
  width: 50%;
}
footer > div svg[data-v-7754c5eb] {
  color: #000;
}
footer > div svg.active[data-v-7754c5eb] {
  fill: #0B8BE8;
}