@charset "UTF-8";
.productItem[data-v-3c542272] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar-content[data-v-3c542272], .countryInput-bottom[data-v-3c542272], .countryInput-top-phoneList-item[data-v-3c542272], .countryInput-top-wrapper[data-v-3c542272], .countryInput-top[data-v-3c542272] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar_liCon[data-v-3c542272] {
  width: 100%;
  float: left;
  overflow: hidden;
}
.rechargeBar_rightBtn[data-v-3c542272] {
  /* float: right; */
  position: absolute;
  right: 0;
  top: 10px;
  margin-right: 14px;
  margin-bottom: 10px;
}
.productItem[data-v-3c542272] {
  width: 90%;
  min-height: 57px;
  border: 1px solid #0c7ad6;
  border-radius: 5px;
  padding: 0 8px;
  color: #0c7ad6;
  margin: 0 1% 14px;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.productItem.active[data-v-3c542272] {
  background-color: #0c7ad6;
  border: 1px solid #0c7ad6;
  color: #fff;
}
.productItem.disabled[data-v-3c542272] {
  border: 1px solid #ddd;
  color: #ddd;
  pointer-events: none;
}
.productItem em[data-v-3c542272] {
  font-weight: bold;
}
.productItem b[data-v-3c542272] {
  font-size: 9px;
}
.productItem p[data-v-3c542272] {
  margin-top: 8px;
  font-size: 16px;
}
.productItem.hot[data-v-3c542272]::after {
  content: attr(data-hotTips);
  position: absolute;
  padding: 0.4em 0.7em;
  border-radius: 1.8em;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 120, 46)), to(rgb(255, 78, 71)));
  background-image: linear-gradient(90deg, rgb(255, 120, 46), rgb(255, 78, 71));
  right: -0.44em;
  top: -1em;
}
header[data-v-3c542272] {
  margin-bottom: -72.5px;
  position: relative;
  z-index: -1;
  overflow: hidden;
  height: 3.68rem;
}
header > p[data-v-3c542272] {
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
}
.rechargeCenter[data-v-3c542272] {
  min-height: 100vh;
  padding-bottom: 50px;
}
.countryInput[data-v-3c542272] {
  -webkit-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin: 0 auto;
  width: 96%;
}
.countryInput-top.bg[data-v-3c542272] {
  opacity: 0.8;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  zoom: 1;
  background-color: #000;
  z-index: 2;
}
.countryInput-top[data-v-3c542272] {
  position: relative;
  height: 65px;
  padding-bottom: 0;
  z-index: 3;
  background-color: #fff;
}
.countryInput-top-img[data-v-3c542272] {
  width: 53px;
}
.countryInput-top-wrapper[data-v-3c542272] {
  border-bottom: 1px solid #dbdbdb;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 7px;
}
.countryInput-top-wrapper > p[data-v-3c542272] {
  color: #999999;
  font-size: 18px;
  font-weight: bold;
}
.countryInput-top-icon[data-v-3c542272] {
  font-size: 56px;
}
.countryInput-top-input[data-v-3c542272] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList[data-v-3c542272] {
  top: 71px;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 0 12px 12px;
}
.countryInput-top-phoneList-item[data-v-3c542272] {
  font-size: 15px;
  color: #2a2a2a;
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f2;
  font-weight: bold;
}
.countryInput-top-phoneList-item p[data-v-3c542272] {
  padding: 0 0.7em 0 0.5em;
}
.countryInput-top-phoneList-item em[data-v-3c542272] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList-item span[data-v-3c542272] {
  color: #888888;
  font-size: 12px;
  font-weight: normal;
}
.countryInput-bottom[data-v-3c542272] {
  color: #25a8f5;
  font-size: 12px;
  padding: 18px 12px;
  padding-top: 0;
}
.countryInput-bottom p.short[data-v-3c542272] {
  width: 56px;
  text-align: center;
}
.countryInput-bottom em[data-v-3c542272] {
  margin-left: 7px;
}
.countryInput-bottom .select[data-v-3c542272] {
  margin-right: 7px;
}
.searchCountryWrapper[data-v-3c542272] {
  padding: 9px 12px;
  margin: 0 auto;
}
.searchCountryWrapper .searchCountry[data-v-3c542272] {
  font-size: 16px;
  padding: 9px 12px;
  border: 1px solid #666666;
  line-height: normal;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar[data-v-3c542272] {
  font-size: 15px;
  margin-top: 4px;
  position: relative;
  width: 96%;
  margin: 10px auto;
}
.rechargeBar-title[data-v-3c542272] {
  font-size: 15px;
  font-weight: bold;
  position: relative;
}
.rechargeBar-title.balanceQuery[data-v-3c542272]::after, .rechargeBar-title.balanceQuery[data-v-3c542272]::before {
  content: "";
  position: absolute;
  font-size: 8px;
}
.rechargeBar-title.balanceQuery[data-v-3c542272]::before {
  background-color: rgb(255, 78, 71);
  border: 4px solid rgb(255, 78, 71);
  -webkit-transform: skew(-20deg, -20deg);
          transform: skew(-20deg, -20deg);
  z-index: 0;
  right: 1.1em;
  top: -0.9em;
}
.rechargeBar-title.balanceQuery[data-v-3c542272]::after {
  content: "先查询";
  color: #fff;
  background-color: rgb(255, 78, 71);
  line-height: 1.2;
  padding: 0.3em 0.5em;
  border-radius: 1.6em;
  right: -1.8em;
  top: -1.5em;
}
.rechargeBar svg[data-v-3c542272] {
  font-size: 17px;
}
.rechargeBar-content[data-v-3c542272] {
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 19px 3%;
}
.order-table[data-v-3c542272] {
  border: 1px solid #eae9ed;
}
.order-table > div[data-v-3c542272] {
  /* display: flex; */
  /* flex-wrap:wrap; */
  border-bottom: 1px solid #eae9ed;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.order-table > div .left[data-v-3c542272] {
  /* border-right: 1px solid #eae9ed; */
  width: 100px;
  font-weight: 600;
  padding: 10px 0;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}
.order-table > div .right[data-v-3c542272] {
  /* flex: 1; */
  padding: 10px 0;
  padding-left: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  word-wrap: break-word;
  word-break: normal;
  float: left;
  border-left: 1px solid #eae9ed;
  width: calc(100% - 100px);
}
.order-table > div[data-v-3c542272]:nth-child(2n+1) {
  background-color: #f2f2f2;
}