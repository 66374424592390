
table[data-v-7754c5eb] {
  table-layout: fixed !important;
  width: 100% !important;
  border-collapse: collapse;
  border: none;
  font-size: 0.23rem;
}
td[data-v-7754c5eb],
th[data-v-7754c5eb] {
  width: 1px;
  white-space: nowrap;
  /* 自适应宽度*/
  word-break: keep-all;
  /* 避免长单词截断，保持全部 */
  border: solid #676767 1px;
  text-align: center;
  white-space: pre-line;
  word-break: break-all !important;
  word-wrap: break-word !important;
  display: table-cell;
  vertical-align: middle !important;
  white-space: normal !important;
  height: auto;
  vertical-align: text-top;
  padding: 2px 2px 0 2px;
  display: table-cell;
  font-size: 14px;
  line-height: 28px;
}
