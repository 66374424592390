#laedFollow[data-v-7f62faa0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.code-img[data-v-7f62faa0] {
  margin: 2em 0;
  width: 60%;
}
.tip-img[data-v-7f62faa0] {
  width: 12%;
}
h1[data-v-7f62faa0] {
  font-size: 0.6rem;
  font-weight: bold;
  color: #333;
  line-height: 3;
}
h2[data-v-7f62faa0] {
  color: #9B9B9B;
  font-size: 0.36rem;
  text-align: center;
}
.tips > p[data-v-7f62faa0] {
  color: #757575;
  font-size: 0.38rem;
  text-align: center;
  line-height: 1.3;
}
.my-img[data-v-7f62faa0] {
  /* width: 100%; */
  height: 100vh;
}