.order-detail[data-v-6df608ad] {
  padding: 20px;
}

/* .header{
    display: flex;
    margin: 20px 0;
} */
.header img[data-v-6df608ad] {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  margin-left: 20px;
}
.header p[data-v-6df608ad] {
  font-size: 20px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}
.order-table[data-v-6df608ad] {
  border: 1px solid #eae9ed;
}
.order-table > div[data-v-6df608ad] {
  /* display: flex; */
  /* flex-wrap:wrap; */
  border-bottom: 1px solid #eae9ed;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.order-table > div .left[data-v-6df608ad] {
  /* border-right: 1px solid #eae9ed; */
  width: 100px;
  font-weight: 600;
  padding: 10px 0;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}
.order-table > div .right[data-v-6df608ad] {
  /* flex: 1; */
  padding: 10px 0;
  padding-left: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  word-wrap: break-word;
  word-break: normal;
  float: left;
  border-left: 1px solid #eae9ed;
  width: calc(100% - 100px);
}
.order-table > div[data-v-6df608ad]:nth-child(2n+1) {
  background-color: #f2f2f2;
}
.order-table2 > div .left[data-v-6df608ad] {
  border-right: 1px solid #eae9ed;
}
.order-table2 > div .right[data-v-6df608ad] {
  border: 0;
}
.order-table2 > div:nth-child(2) .left[data-v-6df608ad] {
  border-right: 1px solid #eae9ed;
}
.order-table2 > div:nth-child(2) .right[data-v-6df608ad] {
  border: 0;
  line-height: 51px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.btn-print[data-v-6df608ad] {
  font-size: 18px;
  border-radius: 10px;
  border: 0;
  padding: 5px 10px;
  background: rgb(84, 149, 229);
  color: #fff;
}
.fansGroupPopup img[data-v-6df608ad] {
  width: 300px;
}