@charset "UTF-8";
.point[data-v-50fb2e1b] {
  width: 100% !important;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-top: 10px;
}
.pointText[data-v-50fb2e1b] {
  font-size: 13px;
}

/* .imageAndText{
    margin-top: 30px;
} */
.carousel-images[data-v-50fb2e1b] {
  /* max-height: 320px; */
  overflow: hidden;
}
.images[data-v-50fb2e1b] {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain; /* 保持图片宽高比，并填充容器 */
}
.more[data-v-50fb2e1b] {
  font-size: 15px;
}
.more .more-button[data-v-50fb2e1b] {
  display: inline-block;
  padding: 10px 45px;
  margin: 5px 0;
  background-color: #f1f4f8;
  border-radius: 50px;
}
.custom-indicator[data-v-50fb2e1b] {
  position: absolute;
  bottom: 10px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
  background: #6d6d6d; /* 可选：背景颜色和透明度 */
  color: white; /* 文字颜色 */
  padding: 5px 10px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  font-size: 14px; /* 字体大小 */
}
.text-all[data-v-50fb2e1b] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-main[data-v-50fb2e1b] {
  font-size: 15px;
  white-space: pre-wrap;
  margin: 3%;
  line-height: 1.8; /* 调整此值以增加或减少行间距 */
  color: #373737;
}
.loading-spinner[data-v-50fb2e1b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%; /* 全屏居中或者容器居中 */
  color: #555;
  font-size: 14px;
  /* 圆圈样式 */
}
.loading-spinner[data-v-50fb2e1b]::before {
  content: "";
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #555; /* 圆圈的主色调 */
  border-radius: 50%;
  -webkit-animation: spin-50fb2e1b 1s linear infinite;
          animation: spin-50fb2e1b 1s linear infinite; /* 转圈动画 */
  margin-bottom: 10px; /* 圆圈与文字间距 */
}
@-webkit-keyframes spin-50fb2e1b {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes spin-50fb2e1b {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}