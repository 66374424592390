.orderWrapper .orderButton[data-v-68667822], .orderWrapper .orderDetail-item[data-v-68667822] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn2[data-v-68667822] {
  width: 100%;
  border: none;
  padding: 15px 0;
  background: #ff4444;
  color: #fff;
}
.orderWrapper > h4[data-v-68667822] {
  padding: 18px 0;
  text-align: center;
  font-size: 15px;
  color: #060606;
  border: 1px solid #f2f2f3;
  font-weight: bold;
}
.orderWrapper .orderDetail-item[data-v-68667822] {
  padding: 13px 4%;
  border-bottom: 1px solid #f2f2f3;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  color: #808080;
}
.orderWrapper .orderDetail-item.radio[data-v-68667822] {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #dfdfdf;
}
.orderWrapper .orderDetail-item label > span[data-v-68667822] {
  color: #999999;
  font-size: 12px;
  background-color: #fbebef;
  margin-left: 30px;
  padding: 6px 7.5px;
}
.orderWrapper .orderDetail-item label > span em[data-v-68667822] {
  color: #323232;
  font-weight: bold;
}
.orderWrapper .orderDetail-item label > span b[data-v-68667822] {
  color: #ea5675;
  font-weight: bold;
}
.orderWrapper .orderDetail-item label > em[data-v-68667822] {
  background-color: #ffd3b9;
  padding: 6px 7.5px;
  color: #ea5675;
  margin-left: 16px;
}
.orderWrapper .orderDetail-item.selected[data-v-68667822] {
  max-height: 100%;
  color: #808080;
}
.orderWrapper .orderDetail-item.disabled[data-v-68667822] {
  max-height: 100%;
  color: #dfdfdf;
}
.orderWrapper .orderDetail-item > p[data-v-68667822] {
  color: #141414;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0.6em;
}
.orderWrapper .orderDetail-item > p.coupon[data-v-68667822] {
  color: #e66228;
}
.orderWrapper .orderDiscountTips[data-v-68667822] {
  display: inline-block;
  color: #108ee8;
  font-size: 12px;
  padding: 13px 4% 0;
}
.orderWrapper .orderButton[data-v-68667822] {
  -webkit-box-shadow: 1px 4px 6px 1px rgba(0, 0, 0, 0.45);
          box-shadow: 1px 4px 6px 1px rgba(0, 0, 0, 0.45);
  border-radius: 100px;
  width: 92%;
  margin: 30px auto 20px;
  background-color: #323232;
  overflow: hidden;
}
.orderWrapper .orderButton > p[data-v-68667822] {
  color: #d3d3d3;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 12px;
  background-color: #323232;
  padding-left: 0.7em;
  margin-top: -3px;
}
.orderWrapper .orderButton > p span[data-v-68667822] {
  color: #fff;
  padding-left: 0.2em;
}
.orderWrapper .orderButton > p b[data-v-68667822] {
  font-weight: bold;
  color: #fff;
  font-size: 18px;
}
.orderWrapper .orderButton > span[data-v-68667822] {
  background-color: #323232;
  color: #d3d3d3;
  font-size: 12px;
  margin-top: -3px;
  padding-right: 18px;
}
.orderWrapper .orderButton > em[data-v-68667822] {
  color: #fff;
  font-size: 15px;
  background-color: #108ee8;
  border: 1px solid #108ee8;
  padding: 13px 17.5px 16.5px 14.5px;
}