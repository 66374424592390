.miniprogram[data-v-44fda724] {
  text-align: center;
  font-size: 15px;
  color: #201F1F;
}
.miniprogram-subtitle[data-v-44fda724] {
  color: #fff;
  font-size: 16px;
}
.miniprogram-subtitle span[data-v-44fda724] {
  font-size: 15px;
}
.miniprogram-title[data-v-44fda724] {
  color: #fff;
  font-size: 24px;
  font-weight: bolder;
  margin: 12px 0 26px;
}
.miniprogram-main[data-v-44fda724] {
  background-color: #fff;
  border-radius: 5px;
  padding: 19px 0 14px;
}
.miniprogram-main-title[data-v-44fda724] {
  color: #525BCC;
  background-color: #F1F1FE;
  padding: 6px 22px;
  border-radius: 50px;
}
.miniprogram-main-code[data-v-44fda724] {
  width: 152px;
  margin: 30px auto 25px;
}
.miniprogram-main-tips[data-v-44fda724] {
  font-size: 12px;
  margin-top: 30px;
}
.miniprogram-bottom[data-v-44fda724] {
  width: 141px;
  margin: 21px auto 0;
}