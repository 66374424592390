.lottery-tb-btn[data-v-09785e30] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.lottery-header[data-v-09785e30], .prizeRecord-list-item[data-v-09785e30], .prizeRecord-title[data-v-09785e30], .artBar[data-v-09785e30] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pointError[data-v-09785e30] {
  padding: 20px;
  width: 80%;
  text-align: center;
  border-radius: 10px;
}
.lotteryRule[data-v-09785e30] {
  font-size: 12px;
  padding: 20px;
  width: 80%;
  line-height: 1.4;
}
.myPrizeRecord[data-v-09785e30] {
  text-align: center;
  font-size: 12px;
  padding-top: 30px;
}
.myPrizeRecord a[data-v-09785e30] {
  color: #0C8CE6;
}
.myPrizeRecord a svg[data-v-09785e30] {
  font-size: 8px;
  vertical-align: 0;
}
.artBar[data-v-09785e30] {
  padding: 30px 3%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.artBar > div[data-v-09785e30] {
  width: 45%;
}
.prizeRecord[data-v-09785e30] {
  font-size: 12px;
  background-color: #fff;
  width: 94%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 12px 2.5px rgba(72, 72, 72, 0.15);
          box-shadow: 0 0 12px 2.5px rgba(72, 72, 72, 0.15);
  border-radius: 8px;
  margin-top: 19px;
}
.prizeRecord-title[data-v-09785e30] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 11px;
  border-bottom: 1px solid #CACACA;
}
.prizeRecord-title cite[data-v-09785e30] {
  color: #F14E3E;
  font-size: 15px;
  font-weight: bolder;
}
.prizeRecord-title a[data-v-09785e30] {
  color: #0C8CE6;
}
.prizeRecord-title a svg[data-v-09785e30] {
  font-size: 8px;
  vertical-align: 0;
}
.prizeRecord-list[data-v-09785e30] {
  padding: 8px 11px 3px;
}
.prizeRecord-list-item[data-v-09785e30] {
  color: #393939;
  margin-bottom: 12px;
}
.prizeRecord-list-item .userImg[data-v-09785e30] {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  overflow: hidden;
}
.prizeRecord-list-item p[data-v-09785e30] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prizeRecord-list-item em[data-v-09785e30] {
  color: #F85543;
  padding: 0 3em;
}
.resultPrize[data-v-09785e30] {
  width: 80%;
  background-color: #4AB151;
  border: 6.5px solid #F8EA74;
  border-radius: 20px;
  text-align: center;
  padding: 15px 15px 35px;
  font-size: 12px;
  color: #fff;
  overflow: visible;
}
.resultPrize > h1[data-v-09785e30] {
  font-size: 24px;
  font-weight: bolder;
  position: relative;
}
.resultPrize > h1[data-v-09785e30]::before {
  content: "";
  width: 90%;
  position: absolute;
  height: 80px;
  top: -45px;
  left: -17%;
  background-image: url(/src/assets/artCenter/lottery/resultPrizeBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.resultPrize > .prizeImg[data-v-09785e30] {
  width: 120px;
  margin: 24px auto 12px;
}
.resultPrize > p[data-v-09785e30] {
  margin: 1.4em 0 4em;
}
.resultPrize > b[data-v-09785e30] {
  color: #A31D03;
  font-size: 15px;
  padding: 0.8em 3.7em;
  background-image: linear-gradient(5deg, rgb(254, 215, 5), rgb(253, 190, 32));
  border-radius: 2.6em;
  -webkit-box-shadow: 0 5.5px 0 0 #CC8D18, 0 10px 15px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 5.5px 0 0 #CC8D18, 0 10px 15px 0 rgba(0, 0, 0, 0.3);
}
*[data-v-09785e30] {
  padding: 0;
  margin: 0;
}
div[data-v-09785e30] {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
.lottery-con[data-v-09785e30] {
  background-color: #fff;
}
.lottery-header[data-v-09785e30] {
  background-image: linear-gradient(-3deg, rgb(252, 120, 91), rgb(245, 55, 46));
  color: #fff;
  padding: 32px 4% 45px;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  margin-bottom: -26px;
}
.lottery-header > p[data-v-09785e30] {
  font-size: 15px;
  margin-right: 7px;
}
.lottery-header > p em[data-v-09785e30] {
  font-size: 18px;
  font-weight: bolder;
}
.lottery-header > a[data-v-09785e30] {
  background-color: #C53224;
  padding: 0.3em 0.7em;
  border-radius: 1.6em;
  font-size: 12px;
}
.lottery-header > a svg[data-v-09785e30] {
  font-size: 8px;
  vertical-align: 0;
}
.lottery-body[data-v-09785e30] {
  background-color: #fff;
  -webkit-box-shadow: 0 0 12px 2.5px rgba(72, 72, 72, 0.15);
          box-shadow: 0 0 12px 2.5px rgba(72, 72, 72, 0.15);
  border-radius: 8px;
  padding: 17px 9px;
  width: 94%;
  margin: 0 auto;
}
.lottery-thead[data-v-09785e30] {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.lottery-thead > p[data-v-09785e30] {
  color: #2E2E2E;
  font-size: 15px;
  font-weight: bold;
}
.lottery-thead > p small[data-v-09785e30] {
  font-weight: normal;
  font-size: 12px;
  color: #999;
  margin-left: 0.6em;
}
.lottery-thead > span[data-v-09785e30] {
  color: #7D7D7D;
  font-size: 12px;
}
.lottery-thead > span svg[data-v-09785e30] {
  margin-left: 6px;
}
.lottery-tbody[data-v-09785e30] {
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(254, 152, 0)), to(rgb(255, 86, 10)));
  background-image: linear-gradient(-270deg, rgb(254, 152, 0), rgb(255, 86, 10));
  border-radius: 5px;
  padding: 4.5px;
}
.lottery-tb-inner[data-v-09785e30] {
  position: relative;
}
.lottery-tb-inner.shuffle .lottery-mask[data-v-09785e30] {
  display: block;
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(1) {
  -webkit-transform: translate3d(104%, 90px, 0);
  transform: translate3d(104%, 90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(2) {
  -webkit-transform: translate3d(0, 90px, 0);
  transform: translate3d(0, 90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(3) {
  -webkit-transform: translate3d(-104%, 90px, 0);
  transform: translate3d(-104%, 90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(4) {
  -webkit-transform: translate3d(-104%, 0, 0);
  transform: translate3d(-104%, 0, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(5) {
  -webkit-transform: translate3d(-104%, -90px, 0);
  transform: translate3d(-104%, -90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(6) {
  -webkit-transform: translate3d(0, -90px, 0);
  transform: translate3d(0, -90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(7) {
  -webkit-transform: translate3d(104%, -90px, 0);
  transform: translate3d(104%, -90px, 0);
}
.lottery-tb-inner.shuffle .lottery-tb-list li[data-v-09785e30]:nth-child(8) {
  -webkit-transform: translate3d(104%, 0, 0);
  transform: translate3d(104%, 0, 0);
}
.lottery-tb-btn[data-v-09785e30] {
  width: 31.6%;
  height: 80px;
  border-radius: 5px;
  position: absolute;
  left: 49.67%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 90px;
  background-color: rgb(255, 236, 114);
  -webkit-box-shadow: 0 5px 0 0 #FECA25;
          box-shadow: 0 5px 0 0 #FECA25;
  color: #DD0606;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.lottery-tb-btn > em[data-v-09785e30] {
  font-size: 14px;
  font-weight: bold;
}
.lottery-tb-btn > p[data-v-09785e30] {
  font-size: 24px;
  font-weight: bolder;
  margin: 2px 0 6px;
}
.lottery-tb-btn > p.scale[data-v-09785e30] {
  animation: scale-09785e30 0.3s infinite alternate-reverse;
}
@-webkit-keyframes scale-09785e30 {
0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
}
@keyframes scale-09785e30 {
0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
}
.lottery-tb-btn > small[data-v-09785e30] {
  font-size: 10px;
}
.lottery-tb-btn.disabled[data-v-09785e30] {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #eee;
  -webkit-box-shadow: 0 5px 0 0 #ddd;
          box-shadow: 0 5px 0 0 #ddd;
  color: #aaa;
}
.lottery-tb-btn-d[data-v-09785e30] {
  -webkit-box-shadow: 0 0 0 0 #FECA25;
          box-shadow: 0 0 0 0 #FECA25;
  height: 85px;
}
.lottery-tb-list[data-v-09785e30] {
  position: relative;
  height: 266px;
}
.lottery-tb-list li[data-v-09785e30] {
  width: 32.2%;
  height: 85px;
  border-radius: 5px;
  position: absolute;
  overflow: hidden;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  z-index: 2;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(1) {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  left: 0;
  top: 0;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(2) {
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
  left: 33.6%;
  top: 0;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(3) {
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
  left: 67.2%;
  top: 0;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(4) {
  -webkit-transition-delay: 400ms;
  transition-delay: 400ms;
  left: 67.2%;
  top: 90px;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(5) {
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  left: 67.2%;
  top: 180px;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(6) {
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
  left: 33.6%;
  top: 180px;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(7) {
  -webkit-transition-delay: 700ms;
  transition-delay: 700ms;
  left: 0;
  top: 180px;
}
.lottery-tb-list li[data-v-09785e30]:nth-child(8) {
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
  left: 0;
  top: 90px;
}
.lottery-tb-list .lottery-playing-cards[data-v-09785e30] {
  width: 100%;
  height: 100%;
}
.lottery-tb-list .lottery-flip-card[data-v-09785e30] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 3px;
}
.lottery-tb-list .lottery-flip-card p[data-v-09785e30] {
  font-size: 12px;
  color: #333;
  margin-top: -10px;
  text-align: center;
}
.lottery-tb-list > li:not(.bigPrize) .lottery-flip-card.lottery-positive img[data-v-09785e30] {
  margin-top: -10px;
}
.lottery-tb-list .lottery-positive[data-v-09785e30] {
  background-color: #fff;
}
.lottery-mask[data-v-09785e30] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: none;
}
.lottery-mask.disabled[data-v-09785e30] {
  display: block;
}
.lottery-msg[data-v-09785e30] {
  text-align: center;
  font-size: 0.375rem;
  color: orangered;
  line-height: 1rem;
}
.in[data-v-09785e30] {
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-duration: 350ms;
}
.out[data-v-09785e30] {
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 225ms;
  animation-timing-function: ease-in;
  animation-duration: 225ms;
}
.viewport-flip[data-v-09785e30] {
  -webkit-perspective: 1000;
  perspective: 1000;
  position: absolute;
  left: 0;
  top: 0;
}
.flip[data-v-09785e30] {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateX(0);
  backface-visibility: hidden;
  transform: translateX(0);
}
.flip.out[data-v-09785e30] {
  -webkit-transform: rotateY(-90deg) scale(0.9);
  -webkit-animation-name: flipouttoleft-09785e30;
  -webkit-animation-duration: 175ms;
  transform: rotateY(-90deg) scale(0.9);
  animation-name: flipouttoleft-09785e30;
  animation-duration: 175ms;
}
.flip.in[data-v-09785e30] {
  -webkit-animation-name: flipintoright-09785e30;
  -webkit-animation-duration: 225ms;
  animation-name: flipintoright-09785e30;
  animation-duration: 225ms;
}
.flip.out.reverse[data-v-09785e30] {
  -webkit-transform: rotateY(90deg) scale(0.9);
  -webkit-animation-name: flipouttoright-09785e30;
  transform: rotateY(90deg) scale(0.9);
  animation-name: flipouttoright-09785e30;
}
.flip.in.reverse[data-v-09785e30] {
  -webkit-animation-name: flipintoleft-09785e30;
  animation-name: flipintoleft-09785e30;
}
@-webkit-keyframes flipouttoleft-09785e30 {
from {
    -webkit-transform: rotateY(0);
}
to {
    -webkit-transform: rotateY(-90deg) scale(0.9);
}
}
@keyframes flipouttoleft-09785e30 {
from {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}
to {
    -webkit-transform: rotateY(-90deg) scale(0.9);
            transform: rotateY(-90deg) scale(0.9);
}
}
@-webkit-keyframes flipouttoright-09785e30 {
from {
    -webkit-transform: rotateY(0);
}
to {
    -webkit-transform: rotateY(90deg) scale(0.9);
}
}
@keyframes flipouttoright-09785e30 {
from {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}
to {
    -webkit-transform: rotateY(90deg) scale(0.9);
            transform: rotateY(90deg) scale(0.9);
}
}
@-webkit-keyframes flipintoleft-09785e30 {
from {
    -webkit-transform: rotateY(-90deg) scale(0.9);
}
to {
    -webkit-transform: rotateY(0);
}
}
@keyframes flipintoleft-09785e30 {
from {
    -webkit-transform: rotateY(-90deg) scale(0.9);
            transform: rotateY(-90deg) scale(0.9);
}
to {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}
}
@-webkit-keyframes flipintoright-09785e30 {
from {
    -webkit-transform: rotateY(90deg) scale(0.9);
}
to {
    -webkit-transform: rotateY(0);
}
}
@keyframes flipintoright-09785e30 {
from {
    -webkit-transform: rotateY(90deg) scale(0.9);
            transform: rotateY(90deg) scale(0.9);
}
to {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}
}