.header-bar[data-v-81cd44ee] {
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 120, 46)), to(rgb(255, 78, 71)));
  background-image: linear-gradient(90deg, rgb(255, 120, 46), rgb(255, 78, 71));
}
.header-bar .img-403[data-v-81cd44ee] {
  width: 40%;
  margin: 0 auto;
  position: relative;
  bottom: -2rem;
}
.middle-bar[data-v-81cd44ee] {
  padding-top: 3.2rem;
  text-align: center;
}
.middle-bar strong[data-v-81cd44ee] {
  font-size: 0.5rem;
  color: #505050;
}
.middle-bar p[data-v-81cd44ee] {
  font-size: 0.36rem;
  color: #999999;
  line-height: 4;
  margin-bottom: 0.5rem;
}
.middle-bar .return-btn[data-v-81cd44ee] {
  padding: 0 3em;
}