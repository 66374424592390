
.containers[data-v-0b5ff5d1] {
  width: 100%;
  height: calc(100vh - 92px);
  background-size: cover;
  position: relative;
}
.containers #subscribe-btn[data-v-0b5ff5d1] {
  color: #fff;
  background-color: #07c160;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
