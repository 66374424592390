.carrierWrapper > h4[data-v-16c5706c] {
  font-size: 18px;
  color: #191919;
  padding: 18px 4%;
  font-weight: bold;
}
.carrierWrapper-carrierList[data-v-16c5706c] {
  font-size: 15px;
  color: #787878;
  padding-bottom: 25px;
}
.carrierWrapper-carrierList-item[data-v-16c5706c] {
  padding: 10px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}
.carrierWrapper-carrierList-item[data-v-16c5706c]:active {
  background-color: #f2f3f5;
}
.carrierWrapper-carrierList-item svg[data-v-16c5706c] {
  font-size: 22px;
  fill: #b5b5b5;
}
.carrierWrapper-carrierList-item .fill[data-v-16c5706c] {
  fill: #fc6e50;
}
.carrierWrapper-carrierList-item-left[data-v-16c5706c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.carrierWrapper-carrierList-item-img[data-v-16c5706c] {
  width: 50px;
  margin-right: 20px;
}