.waitOrderPopup[data-v-c8ff82c4] {
  height: 22%;
  border-radius: 15px;
  font-size: 12px;
  width: 80%;
  color: #414141;
  line-height: 1;
  overflow: hidden;
}
.waitOrderPopup-title[data-v-c8ff82c4] {
  font-size: 16px;
  color: #fff;
  padding: 5% 0;
  text-align: center;
}
.waitOrderPopup-content[data-v-c8ff82c4] {
  margin: 5% 25%;
  margin-top: 0;
  margin-bottom: 10%;
  white-space: nowrap;
  text-align: center;
}
.waitOrderPopup-item[data-v-c8ff82c4] {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.waitOrderPopup-item-discount[data-v-c8ff82c4] {
  color: #ff9a22;
}
.waitOrderPopup-item-payPrice[data-v-c8ff82c4] {
  color: #ff3c3c;
}
.waitOrderPopup-item label[data-v-c8ff82c4] {
  color: #414141;
  font-size: 18px;
  text-align: center;
  font-weight: 900;
}
.waitOrderPopup-item[data-v-c8ff82c4]:last-child {
  margin-bottom: 0;
}
.waitOrderPopup-button[data-v-c8ff82c4] {
  /* padding-top:7%; */
  width: 100%;
  /*height: 500px; */
  border-top: 1px solid #d9d9d9;
  position: relative;
  text-align: center;
  height: 20%;
  /* line-height: 30%; */
}