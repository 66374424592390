@charset "UTF-8";
.rechargeBar-content[data-v-7754c5eb], .countryInput-bottom[data-v-7754c5eb], .countryInput-top-phoneList-item[data-v-7754c5eb], .countryInput-top-wrapper[data-v-7754c5eb], .countryInput-top[data-v-7754c5eb] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
header[data-v-7754c5eb] {
  margin-bottom: -72.5px;
  position: relative;
  z-index: -1;
  overflow: hidden;
  height: 3.68rem;
}
header > p[data-v-7754c5eb] {
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
}
.rechargeCenter[data-v-7754c5eb] {
  min-height: 100vh;
  padding-bottom: 50px;
}
.countryInput[data-v-7754c5eb] {
  -webkit-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin: 0 auto;
  width: 96%;
}
.countryInput-top.bg[data-v-7754c5eb] {
  opacity: 0.8;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  zoom: 1;
  background-color: #000;
  z-index: 2;
}
.countryInput-top[data-v-7754c5eb] {
  position: relative;
  padding: 18px 12px;
  padding-bottom: 0;
  z-index: 3;
  background-color: #fff;
}
.countryInput-top-img[data-v-7754c5eb] {
  width: 53px;
}
.countryInput-top-wrapper[data-v-7754c5eb] {
  border-bottom: 1px solid #dbdbdb;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 7px;
}
.countryInput-top-wrapper > p[data-v-7754c5eb] {
  color: #999999;
  font-size: 18px;
  font-weight: bold;
}
.countryInput-top-icon[data-v-7754c5eb] {
  font-size: 56px;
}
.countryInput-top-input[data-v-7754c5eb] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList[data-v-7754c5eb] {
  top: 71px;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 0 12px 12px;
}
.countryInput-top-phoneList-item[data-v-7754c5eb] {
  font-size: 15px;
  color: #2a2a2a;
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f2;
  font-weight: bold;
}
.countryInput-top-phoneList-item p[data-v-7754c5eb] {
  padding: 0 0.7em 0 0.5em;
}
.countryInput-top-phoneList-item em[data-v-7754c5eb] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList-item span[data-v-7754c5eb] {
  color: #888888;
  font-size: 12px;
  font-weight: normal;
}
.countryInput-bottom[data-v-7754c5eb] {
  color: #25a8f5;
  font-size: 12px;
  padding: 18px 12px;
  padding-top: 0;
}
.countryInput-bottom p.short[data-v-7754c5eb] {
  width: 56px;
  text-align: center;
}
.countryInput-bottom em[data-v-7754c5eb] {
  margin-left: 7px;
}
.select[data-v-7754c5eb] {
  margin-left: 7px;
  position: absolute;
  bottom: 10px;
  left: 40%;
  color: #25a8f5;
  font-size: 14px;
}
.searchCountryWrapper[data-v-7754c5eb] {
  padding: 9px 12px;
  margin: 0 auto;
}
.searchCountryWrapper .searchCountry[data-v-7754c5eb] {
  font-size: 16px;
  padding: 9px 12px;
  border: 1px solid #666666;
  line-height: normal;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar[data-v-7754c5eb] {
  font-size: 15px;
  margin-top: 4px;
  position: relative;
}
.rechargeBar-title[data-v-7754c5eb] {
  font-size: 15px;
  font-weight: bold;
  position: relative;
}
.rechargeBar-title.balanceQuery[data-v-7754c5eb]::after, .rechargeBar-title.balanceQuery[data-v-7754c5eb]::before {
  content: "";
  position: absolute;
  font-size: 8px;
}
.rechargeBar-title.balanceQuery[data-v-7754c5eb]::before {
  background-color: rgb(255, 78, 71);
  border: 4px solid rgb(255, 78, 71);
  -webkit-transform: skew(-20deg, -20deg);
          transform: skew(-20deg, -20deg);
  z-index: 0;
  right: 1.1em;
  top: -0.9em;
}
.rechargeBar-title.balanceQuery[data-v-7754c5eb]::after {
  content: "先查询";
  color: #fff;
  background-color: rgb(255, 78, 71);
  line-height: 1.2;
  padding: 0.3em 0.5em;
  border-radius: 1.6em;
  right: -1.8em;
  top: -1.5em;
}
.rechargeBar svg[data-v-7754c5eb] {
  font-size: 17px;
}
.rechargeBar-content[data-v-7754c5eb] {
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 19px 3%;
}
.type[data-v-7754c5eb] {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 10px;
}
.title-phone[data-v-7754c5eb] {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}
.submit-btn[data-v-7754c5eb] {
  text-align: center;
  background-color: #25a8f5;
  line-height: 44px;
  height: 44px;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  width: calc(100% - 40px);
  color: #fff;
  font-size: 16px;
}