.couponList[data-v-31072d64] {
  position: relative;
  padding: 9px 3%;
  min-height: calc(100vh - 44px);
  background-color: #F6F6F6;
}
.couponList-empty[data-v-31072d64] {
  font-size: 16px;
  color: #666;
  padding-top: 30px;
  text-align: center;
}
.couponList-end[data-v-31072d64] {
  font-size: 12px;
  color: #999;
  padding: 10px 0;
  text-align: center;
  margin: 0 auto;
  width: 8em;
  position: relative;
}
.couponList-end[data-v-31072d64]::after, .couponList-end[data-v-31072d64]::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 3em;
  height: 1px;
  background-color: #ccc;
}
.couponList-end[data-v-31072d64]::before {
  left: -3em;
}
.couponList-end[data-v-31072d64]::after {
  right: -3em;
}