.productItem[data-v-01f3952a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.productItem[data-v-01f3952a] {
  min-height: 57px;
  border: 1px solid #0c7ad6;
  border-radius: 5px;
  padding: 0 8px;
  color: #0c7ad6;
  margin: 0 1% 14px;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 31.3%;
  max-width: 31.3%;
}
em[data-v-01f3952a] {
  font-weight: bold;
}