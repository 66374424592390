@charset "UTF-8";
.rechargeBar-content[data-v-1d9b105c], .countryInput-bottom[data-v-1d9b105c], .countryInput-top-phoneList-item[data-v-1d9b105c], .countryInput-top-wrapper[data-v-1d9b105c], .countryInput-top[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vanDiaLog[data-v-1d9b105c] {
  text-align: center;
  position: relative;
  width: 8rem;
  height: 70%;
  font-size: 20px;
  margin-top: 10%;
}
.areaNumberSelect[data-v-1d9b105c] {
  text-align: center;
  width: 8rem;
  font-size: 16px;
}
[data-v-1d9b105c] .van-dialog__header {
  font-weight: 700;
  padding-top: 20px;
}
.my-btn[data-v-1d9b105c]:nth-child(2) {
  margin-bottom: 20px !important;
}
.activeImage[data-v-1d9b105c] {
  display: block;
  width: 300px;
  margin: auto 0;
  /*图片居中*/
}
.button-deletePhoneOne[data-v-1d9b105c] {
  position: absolute;
  right: 4%;
  list-style: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ff455b;
}
header[data-v-1d9b105c] {
  margin-bottom: -72.5px;
  position: relative;
  z-index: -1;
  overflow: hidden;
  height: 3.68rem;
}
header > p[data-v-1d9b105c] {
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
}
.rechargeCenter[data-v-1d9b105c] {
  min-height: 100vh;
  padding-bottom: 50px;
}
.countryInput[data-v-1d9b105c] {
  -webkit-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin: 0 auto;
  width: 96%;
}
.countryInput-top.bg[data-v-1d9b105c] {
  opacity: 0.8;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  zoom: 1;
  background-color: #000;
  z-index: 2;
}
.countryInput-top[data-v-1d9b105c] {
  position: relative;
  padding: 18px 12px;
  padding-bottom: 0;
  z-index: 3;
  background-color: #fff;
}
.countryInput-top-img[data-v-1d9b105c] {
  width: 53px;
}
.countryInput-top-wrapper[data-v-1d9b105c] {
  border-bottom: 1px solid #dbdbdb;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 7px;
}
.countryInput-top-wrapper > p[data-v-1d9b105c] {
  color: #999999;
  font-size: 18px;
  font-weight: bold;
}
.countryInput-top-icon[data-v-1d9b105c] {
  font-size: 56px;
}
.countryInput-top-input[data-v-1d9b105c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList[data-v-1d9b105c] {
  /*top: 71px;*/
  top: 56px;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 0 12px 12px;
}
.countryInput-top-phoneList-item[data-v-1d9b105c] {
  font-size: 14px;
  color: #2a2a2a;
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f2;
  font-weight: bold;
  display: grid;
}
@media screen and (max-width: 200px) {
.countryInput-top-phoneList-item[data-v-1d9b105c] {
    font-size: 5px;
}
.countryInput-top-phoneList-item-top[data-v-1d9b105c] {
    font-size: 5px;
}
.countryInput-top-phoneList-item-top[data-v-1d9b105c] {
    font-size: 5px;
}
.countryInput-top-phoneList-item span[data-v-1d9b105c] {
    color: #888888;
    font-size: 5px;
    font-weight: normal;
}
}
.countryInput-top-phoneList-item-top[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.countryInput-top-phoneList-item-bottom[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2%;
  margin-top: 3px;
  position: relative;
}
.countryInput-top-phoneList-item p[data-v-1d9b105c] {
  padding: 0 0.7em 0 0.5em;
}
.countryInput-top-phoneList-item em[data-v-1d9b105c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.countryInput-top-phoneList-item span[data-v-1d9b105c] {
  color: #888888;
  font-size: 14px;
  font-weight: normal;
}
.countryInput-bottom[data-v-1d9b105c] {
  color: #25a8f5;
  font-size: 12px;
  padding: 10px 12px;
  padding-top: 0;
}
.countryInput-bottom p.short[data-v-1d9b105c] {
  width: 56px;
  text-align: center;
}
.countryInput-bottom em[data-v-1d9b105c] {
  margin-left: 7px;
  margin-top: 6px;
  font-weight: 600;
}
.searchCountryWrapper[data-v-1d9b105c] {
  padding: 9px 12px;
  margin: 0 auto;
}
.searchCountryWrapper .searchCountry[data-v-1d9b105c] {
  font-size: 16px;
  padding: 9px 12px;
  border: 1px solid #666666;
  line-height: normal;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rechargeBar[data-v-1d9b105c] {
  font-size: 15px;
  margin-top: 4px;
  position: relative;
}
.rechargeBar-title[data-v-1d9b105c] {
  font-size: 15px;
  font-weight: bold;
  position: relative;
}
.rechargeBar-title.balanceQuery[data-v-1d9b105c]::after, .rechargeBar-title.balanceQuery[data-v-1d9b105c]::before {
  content: "";
  position: absolute;
  font-size: 8px;
}
.rechargeBar-title.balanceQuery[data-v-1d9b105c]::before {
  background-color: rgb(255, 78, 71);
  border: 4px solid rgb(255, 78, 71);
  -webkit-transform: skew(-20deg, -20deg);
          transform: skew(-20deg, -20deg);
  z-index: 0;
  right: 1.1em;
  top: -0.9em;
}
.rechargeBar-title.balanceQuery[data-v-1d9b105c]::after {
  content: "先查询";
  color: #fff;
  background-color: rgb(255, 78, 71);
  line-height: 1.2;
  padding: 0.3em 0.5em;
  border-radius: 1.6em;
  right: -1.8em;
  top: -1.5em;
}
.rechargeBar svg[data-v-1d9b105c] {
  font-size: 17px;
}
.rechargeBar-content[data-v-1d9b105c] {
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 19px 3%;
}

/* 流量套餐详情对话框样式 */
.orderDetail-box[data-v-1d9b105c] {
  padding: 0 15px;
}
.productDetail[data-v-1d9b105c] {
  text-align: left;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}
.productDetail .productDetail-item[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 35px;
}
.productDetail .productDetail-item .left[data-v-1d9b105c] {
  width: 50%;
  text-align: right;
  color: #888888;
}
.productDetail .productDetail-item .right[data-v-1d9b105c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 10px;
}
.productDetail .productDetail-item[data-v-1d9b105c]:nth-child(n) {
  /* margin-top: 20px; */
}
.deadline[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #6c757d;
}
.my-pin[data-v-1d9b105c] {
  color: #6c757d;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.orderDetail-info[data-v-1d9b105c] {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  /* padding: 0 10px; */
}
.kefu-btn[data-v-1d9b105c] {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #628fe6;
  cursor: pointer;
}
.weizhi[data-v-1d9b105c] {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1;
  opacity: 0.8;
}
.kefu-btn2[data-v-1d9b105c] {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #628fe6;
  position: absolute;
  /* top:89%; */
  bottom: 78px;
  right: 35px;
  cursor: pointer;
  z-index: 1;
}
.chat-kefu-text[data-v-1d9b105c] {
  cursor: pointer;
  width: 60px;
  margin-top: 5px;
}
.xiaoxi-btn[data-v-1d9b105c] {
  color: #fff !important;
  /* text-align: center; */
  font-size: 35px !important;
  padding-left: 7px;
  padding-top: 6px;
  display: block;
}
.resultQuery[data-v-1d9b105c] {
  position: fixed;
  left: 50%;
  bottom: 52px;
  width: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 750px;
  text-align: center;
  color: #25a8f5;
  font-size: 14px;
  background-color: #fff;
  line-height: 30px;
  cursor: pointer;
}
.couponeUser .box[data-v-1d9b105c] {
  width: 34px;
  height: 34px;
  border: 1px solid #fff;
  border-radius: 17px;
  position: relative;
  margin: 15px auto 0;
}
.couponeUser .box .coupone-chahao[data-v-1d9b105c] {
  color: #fff !important;
  font-size: 24px !important;
  position: absolute;
  top: 2.5px;
  left: 3.5px;
}
.closeBtn[data-v-1d9b105c] {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #25a8f5;
  color: #000;
}
.closeBtnCancel[data-v-1d9b105c] {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: #f7f7f7;
  color: #000;
}
.closeBtn2[data-v-1d9b105c] {
  background-color: #f7f7f7;
  color: #646464;
}
.first-charge-tips[data-v-1d9b105c] {
  font-size: 14px;
}
.countryInput-top-phoneList-item[data-v-1d9b105c] {
  position: relative;
}
.countryInput-top-phoneList-item .rechargeDateTips[data-v-1d9b105c] {
  font-size: 12px;
  position: absolute;
  left: 165px;
  top: 13px;
}
.countryInput-top-phoneList-item .phonedeleteButton[data-v-1d9b105c] {
  width: 20px;
  height: 20px;
  /* 移除内边距和边框 */
  padding: 0;
  border: none;
  /*  移除默认的按钮样式 */
  /* outline: none; */
  /* 背景图片如何覆盖按钮 */
  background-size: cover;
  position: absolute;
  top: 30%;
  right: 10px;
  background-image: url(/src/assets/home/icons8-cancel.png);
  background-color: #fff;
}
.phonelistbox[data-v-1d9b105c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #2a2a2a;
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f2;
  font-weight: bold;
  position: relative;
}