@charset "UTF-8";
/* 问题卡片样式 */
.question-card[data-v-19b271c0] {
  width: 95%;
  margin: 10px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

/* 自定义单选框样式 */
.custom-radio[data-v-19b271c0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0;
  cursor: pointer;
}
.custom-radio input[type=radio][data-v-19b271c0] {
  display: none; /* 隐藏默认单选框 */
}
.radio-square[data-v-19b271c0] {
  width: 16px;
  height: 16px;
  border: 2px solid #2839fe;
  border-radius: 4px; /* 控制圆角，设置为 0 会是正方形 */
  margin-right: 10px;
  display: inline-block;
  position: relative;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.custom-radio input[type=radio]:checked + .radio-square[data-v-19b271c0] {
  background-color: #2839fe;
  border-color: #2839fe;
}
.custom-radio input[type=radio]:checked + .radio-square[data-v-19b271c0]::after {
  content: "";
  width: 6px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg); /* 旋转打勾 */
}

/* 输入框样式 */
.input-reason[data-v-19b271c0] {
  margin: 10px 0;
}
.input-reason input[data-v-19b271c0] {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}
.input-reason input[data-v-19b271c0]:focus {
  border-color: #2839fe;
  -webkit-box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
          box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
}
.submit-button[data-v-19b271c0]:hover {
  background-color: #005bb5;
}

/* 自定义多选框样式 */
.custom-checkbox[data-v-19b271c0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0;
  cursor: pointer;
}
.custom-checkbox input[type=checkbox][data-v-19b271c0] {
  display: none; /* 隐藏默认多选框 */
}
.checkbox-square[data-v-19b271c0] {
  width: 16px;
  height: 16px;
  border: 2px solid #2839fe;
  border-radius: 4px; /* 控制圆角，设置为 0 会是正方形 */
  margin-right: 10px;
  display: inline-block;
  position: relative;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.custom-checkbox input[type=checkbox]:checked + .checkbox-square[data-v-19b271c0] {
  background-color: #2839fe;
  border-color: #2839fe;
}
.custom-checkbox input[type=checkbox]:checked + .checkbox-square[data-v-19b271c0]::after {
  content: "";
  width: 6px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg); /* 旋转打勾 */
}

/* 提交按钮样式 */
.submit-button[data-v-19b271c0] {
  width: 95%;
  margin: 20px auto;
  padding: 15px;
  text-align: center;
  background-color: #2839fe;
  color: #ffffff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.submit-button[data-v-19b271c0]:hover {
  background-color: #2839fe;
}