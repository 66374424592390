.popup-coupon[data-v-3d476906] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.popup-coupon[data-v-3d476906] {
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
}
.popup-coupon.HF[data-v-3d476906] {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(242, 79, 75)), to(rgb(242, 98, 59)));
  background-image: linear-gradient(0deg, rgb(242, 79, 75), rgb(242, 98, 59));
}
.popup-coupon.HF-info p[data-v-3d476906] {
  color: #A21508;
}
.popup-coupon.LL[data-v-3d476906] {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#179cf9), to(#11b5f8));
  background-image: linear-gradient(0deg, #179cf9, #11b5f8);
}
.popup-coupon.LL-info p[data-v-3d476906] {
  color: #06467D;
}
.popup-coupon-price[data-v-3d476906] {
  font-weight: bolder;
  padding: 18px 0;
  text-align: center;
  width: 5em;
  border-color: #ffeaea;
  border-width: 0 1px 0 0;
  border-style: dashed;
  position: relative;
}
.popup-coupon-price em[data-v-3d476906] {
  font-size: 21px;
}
.popup-coupon-price[data-v-3d476906]::before, .popup-coupon-price[data-v-3d476906]::after {
  content: "";
  position: absolute;
  right: -5px;
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.popup-coupon-price[data-v-3d476906]::before {
  top: -5px;
}
.popup-coupon-price[data-v-3d476906]::after {
  bottom: -5px;
}
.popup-coupon-info[data-v-3d476906] {
  padding: 0 1em;
  text-align: left;
  font-size: 15px;
}
.popup-coupon-info p[data-v-3d476906] {
  color: #A21508;
  font-size: 12px;
  margin-top: 6px;
}