@charset "UTF-8";
.rechargeBar-content-empty-tips[data-v-65d7357a], .productItem[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flowProductDetail[data-v-65d7357a], .rechargeBar-content[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.productItem[data-v-65d7357a] {
  width: 31.3%;
  max-width: 31.3%;
  min-height: 57px;
  min-width: 114px;
  color: #0c7ad6;
  border: 1px solid #0c7ad6;
  border-radius: 5px;
  padding: 2px 8px;
  margin: 0 1% 14px;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.batchItem[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 74px;
  width: 115px;
  margin: 0 1% 14px;
  border-radius: 5px;
  color: #0c7ad6;
  border: 1px solid #0c7ad6;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; /* 水平居中 */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* 垂直居中 */
  /* 将整个元素向上位移 10px */
  /* transform: translateY(-10px); */
}
.rechargeBar-content[data-v-65d7357a] {
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px 3%;
  position: relative;
}
.rechargeBar-content-empty[data-v-65d7357a] {
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
}
.rechargeBar-content-empty-tips[data-v-65d7357a] {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 1;
  color: #979797;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.rechargeBar-content-empty-tips svg[data-v-65d7357a] {
  color: #0c7ad6;
  margin-right: 0.4em;
}
.rechargeBar-content-empty > div[data-v-65d7357a] {
  width: 50%;
  margin: 0 auto 1em;
}
.rechargeBar-content-empty p[data-v-65d7357a] {
  color: #808080;
  font-size: 14px;
  margin-bottom: 0.8em;
}
.flowProductDetail[data-v-65d7357a] {
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding: 20px 3%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flowProductDetail-info[data-v-65d7357a] {
  padding-right: 0.4em;
}
.flowProductDetail-info > p[data-v-65d7357a] {
  font-size: 18px;
  color: #0e0e0e;
  font-weight: bold;
  margin-bottom: 10px;
}
.flowProductDetail-info > p em[data-v-65d7357a] {
  font-size: 12px;
  color: #108ee8;
  border: 1px solid;
  padding: 4px 6px;
  font-weight: normal;
  vertical-align: 0.2em;
  border-radius: 3px;
}
.flowProductDetail-info > span[data-v-65d7357a] {
  color: #737373;
  font-size: 12px;
}
.flowProductDetail-buy > p[data-v-65d7357a] {
  color: #ff4e47;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}
.flowProductDetail-buy > div[data-v-65d7357a] {
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  background-color: #108ee8;
  border-radius: 5px;
  padding: 7px 13px;
  margin-top: 12px;
  white-space: nowrap;
}
.line_class[data-v-65d7357a] {
  width: 100% !important;
  text-align: left;
  font-size: 18px;
  font-weight: 800;
  color: #343434;
  margin-bottom: 13px;
  margin-left: 10px;
}
.flowicon[data-v-65d7357a] {
  height: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  margin: 0 auto;
  color: yellow;
}
.point[data-v-65d7357a] {
  width: 100% !important;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 13px;
  margin-left: 10px;
}
.kefu-btn[data-v-65d7357a] {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #628fe6;
  position: fixed;
  bottom: 100px;
  right: 20px;
  cursor: pointer;
}
.chat-kefu-text[data-v-65d7357a] {
  position: fixed;
  bottom: 80px;
  right: 20px;
  cursor: pointer;
}
.xiaoxi-btn[data-v-65d7357a] {
  color: #fff !important;
  /* text-align: center; */
  font-size: 42px !important;
  padding-left: 3px;
  padding-top: 2px;
  display: block;
}
.batch[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
  border: 1px solid #0c7ad6;
  border-radius: 10px;
  background-color: rgba(31, 99, 203, 0.3);
  width: 95%;
  margin: 0 auto 5px;
}
.batch .logo[data-v-65d7357a] {
  width: 45px;
  height: 45px;
  margin-top: 5px;
}
.batch .logo img[data-v-65d7357a] {
  width: 45px;
  height: 45px;
}
.batch .title[data-v-65d7357a] {
  min-width: 55%;
  margin-left: 14px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.batch .title .name[data-v-65d7357a] {
  color: #000;
  margin-bottom: 5px;
  font-size: 20px;
}
.batch .title .desc[data-v-65d7357a] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  margin-top: 7px;
  font-weight: 700;
}
.batch .guanzhu[data-v-65d7357a] {
  /* padding: 0 15px; */
  padding: 0 8px;
  color: #1aad19;
  border: 1px solid #1aad19;
  height: 25px;
  line-height: 24px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 5px;
}
.batch2[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 6px;
  border: 1px solid #ddd;
  background-color: #fff;
  /* border-radius: 10px; */
  /* background-color: rgba(31, 99, 203,.3); */
  width: 100%;
  margin: 0 auto 5px;
}
.batch2 .logo[data-v-65d7357a] {
  width: 45px;
  height: 45px;
  margin-top: 5px;
}
.batch2 .logo img[data-v-65d7357a] {
  width: 45px;
  height: 45px;
}
.batch2 .title[data-v-65d7357a] {
  min-width: 55%;
  margin-left: 14px;
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.batch2 .title .name[data-v-65d7357a] {
  /* color: #000; */
  margin-bottom: 5px;
  font-size: 16px;
}
.batch2 .title .desc[data-v-65d7357a] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-top: 7px;
  /* font-weight: 700; */
  color: #a8a8a8;
}
.batch2 .guanzhu[data-v-65d7357a] {
  /* padding: 0 15px; */
  padding: 0 8px;
  color: #fff;
  border: 1px solid #08affd;
  background-color: #08affd;
  height: 25px;
  line-height: 24px;
  font-size: 14px;
  margin-top: 18px;
  border-radius: 5px;
}
.my_tips[data-v-65d7357a] {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.my-swipe[data-v-65d7357a] {
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  height: 100px;
}
.my-swipe img[data-v-65d7357a] {
  width: 100%;
  height: 100%;
}
.my-swipe .van-swipe-item[data-v-65d7357a] {
  color: #fff;
  /* font-size: 20px; */
  line-height: 100px;
  text-align: center;
  background-color: #39a9ed;
  border-radius: 10px;
}
.closeBtn[data-v-65d7357a] {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #25a8f5;
  color: #000;
}
.closeBtnCancel[data-v-65d7357a] {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: #f7f7f7;
  color: #000;
}
.skipBtnDetail[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin: 8px 16px;
  padding: 8px 0px;
  /* width: 100%; */
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #525151;
}
.skipBtnDetail .skipBtnDetailIcon[data-v-65d7357a] {
  width: 24px;
  height: 24px;
  border-radius: 16px;
}
.closeBtn2[data-v-65d7357a] {
  background-color: #f7f7f7;
  color: #646464;
}
.select_points[data-v-65d7357a] {
  margin: 0px 4% 5px 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fae6ea;
  border-radius: 10px;
  padding: 5px 15px;
  width: 92%; /* 根据需要调整宽度 */
  height: 50px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.select_points img[data-v-65d7357a] {
  margin-right: 5px;
}
.message[data-v-65d7357a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 16px;
  color: #333;
}
.highlight[data-v-65d7357a] {
  color: #ea484c;
  font-weight: bold;
}
.select_red_packet[data-v-65d7357a] {
  margin: 0 4%;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fae6ea;
  border-radius: 10px;
  padding: 0px 15px;
  width: 92%; /* 根据需要调整宽度 */
  height: 50px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.select_red_packet img[data-v-65d7357a] {
  margin-right: 10px;
}
.checkMore[data-v-65d7357a] {
  min-height: 70px;
}
.productList[data-v-65d7357a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; /* 让所有子元素高度匹配 */
}