
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.couponItem {
  position: relative; /* 为了使绝对定位的按钮相对于这个容器 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; /* 使用 Flexbox 布局 */
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; /* 在主轴上均匀分布子元素 */
  border-radius: 8px 8px 0 0; /* 设置圆角 */
  margin: 10px 0; /* 设置上下外边距 */
  margin-bottom: 0px;
  background-color: #fff; /* 设置背景颜色为白色 */
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /*        设置阴影 */
  overflow: hidden; /* 防止内容溢出 */
}
.couponItem-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; /* 使用 Flexbox 布局 */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; /* 垂直排列子元素 */
  background-color: #f9f1ef;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* 子元素水平居中 */
  text-align: center; /* 文本水平居中 */
  width: 30%; /* 设置盒子宽度 */
}
.amount {
  padding-top: 19%;
  font-size: 34px; /* 设置字体大小 */
  font-weight: bold; /* 设置字体加粗 */
  color: #ff1e02; /* 设置字体颜色 */
}
.fuhao {
  font-size: 18px;
}
.info {
  margin-top: 8px; /* 设置上外边距 */
  font-size: 14px; /* 设置字体大小 */
  font-weight: bold;
  color: #ff1e02; /* 设置字体颜色 */
}
.type {
  margin-top: 8px; /* 设置上外边距 */
  font-size: 10px; /* 设置字体大小 */
  color: #ff1e02; /* 设置字体颜色 */
  padding: 3px;
  border: 1px solid #ff1e02;
  margin-bottom: 20%;
  border-radius: 8px; /* 设置圆角 */
}
.couponItem-right {
  display: left; /* 使用 Flexbox 布局 */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; /* 垂直排列子元素 */
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; /* 在主轴上均匀分布子元素 */
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 70%; /* 设置盒子宽度 */
  background-color: #fff;
}
.name {
  margin-top: 7%;
  margin-left: 5%;
  font-size: 16px; /* 设置字体大小 */
  font-weight: bold; /* 设置字体加粗 */
}
.tag {
  font-size: 10px;
  margin-left: 5%;
  margin-top: 5%;
}
.text {
  margin-right: 2%;
  color: #ff1e02;
  font-weight: bold;
  padding: 1%;
  border: 1px solid #ff1e04;
  border-radius: 0px; /* 设置圆角 */
}
.time {
  margin-top: 5%; /* 设置上外边距 */
  margin-left: 5%;
  font-size: 12px; /* 设置字体大小 */
  color: #999; /*设置字体颜色 */
}
.details {
  margin-top: 3%;
  margin-left: 5%;
  font-size: 14px;
  color: #999; /*设置字体颜色 */
}
.couponItem-button {
  position: absolute; /* 绝对定位 */
  top: 36px;
  right: 16px;
  margin-top: 16px; /* 设置上外边距 */
  padding: 8px 8px; /* 设置内边距 */
  background-color: #ff1e02; /* 设置背景颜色 */
  color: #fff; /* 设置字体颜色 */
  border-radius: 50px; /* 设置圆角 */
  /* width: 100px; */
}
.grayColorBorder {
  border: 1px solid #fff;
}
.grayColor {
  background-color: #999; /* 设置背景颜色 */
}
.whileColorText {
  color: #fff;
}
.couponItem-button span {
  font-size: 14px; /* 设置字体大小 */
}
.couponItem-bottom {
  padding: 10px 0 5px 3px; /* 设置上外边距 */
  font-size: 12px; /* 设置字体大小 */
  color: #ff1e02; /*设置字体颜色 */
  text-align: center; /* 文本水平居中 */
  background-color: #fff;
  border-radius: 0 0 8px 8px; /* 设置圆角 */
  text-align: left;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /*        设置阴影 */
}
