.pointItem[data-v-7267d4fa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pointItem[data-v-7267d4fa] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 13px 0;
  border-bottom: 1px solid #f0f0f0;
}
.pointItem-pointInfo em[data-v-7267d4fa] {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: bold;
}
.pointItem-pointInfo p[data-v-7267d4fa] {
  color: #7e7e7e;
  font-size: 10px;
  margin-top: 0.6em;
}
.pointItem-pointNumber[data-v-7267d4fa] {
  color: #fb3f19;
  font-size: 15px;
  font-weight: bold;
}